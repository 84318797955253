import { Logger } from 'frontend-shared-modules/services/logger';

import { safeExecute } from '../Utils/safeExecute';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export interface IStorage {
    storage?: IStorage;
    readonly getItem: (key: string) => any;
    readonly setItem: (key: string, value: string, options?: Readonly<Record<string, any>>) => void;
    readonly removeItem: (key: string) => void;
    readonly clear?: () => void;
}

class StorageProvider implements IStorage {
    constructor(storage: IStorage) {
        this.storage = storage;
    }

    storage: IStorage;

    getItem(key: string) {
        // "bind" has been used in order to prevent "TypeError: Illegal Invocation" exception
        return safeExecute((this.storage?.getItem ?? noop).bind(this.storage), [key]);
    }

    setItem(key: string, value: string) {
        // "bind" has been used in order to prevent "TypeError: Illegal Invocation" exception
        safeExecute((this.storage?.setItem ?? noop).bind(this.storage), [key, value]);
    }

    removeItem(key: string) {
        safeExecute((this.storage?.removeItem ?? noop).bind(this.storage), [key]);
    }

    clear() {
        // "bind" has been used in order to prevent "TypeError: Illegal Invocation" exception
        safeExecute((this.storage?.clear ?? noop).bind(this.storage));
    }
}

const throwWarning = function () {
    Logger.warn('Storage is unavailable.');
};

const noopStorage = {
    getItem: throwWarning,
    setItem: throwWarning,
    removeItem: throwWarning,
    clear: throwWarning,
} as const;

let LSProvider;

let SSProvider;

try {
    LSProvider = new StorageProvider(window.localStorage);
    SSProvider = new StorageProvider(window.sessionStorage);
} catch (err: any) {
    Logger.warn(err);

    LSProvider = new StorageProvider(noopStorage);
    SSProvider = new StorageProvider(noopStorage);
}

export const localStorageProvider = LSProvider;
export const sessionStorageProvider = SSProvider;
