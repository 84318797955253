import React from 'react';
import {
    typography as sharedTypography,
    TypographyVariantOverrides,
    Variants,
    VariantsOptions,
} from 'frontend-shared-modules/theme/typography';

declare module '@mui/material/styles' {
    interface TypographyVariants extends Variants {
        h3: React.CSSProperties;
        h4: React.CSSProperties;
    }

    interface TypographyVariantsOptions extends VariantsOptions {
        h3?: React.CSSProperties;
        h4?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides extends TypographyVariantOverrides {
        h3: true;
        h4: true;
    }
}

export const typography = {
    ...sharedTypography,
    fontFamily: ['GT America', 'sans-serif'].join(','),
    h3: {
        fontFamily: ['TWK Everett', 'sans-serif'].join(','),
        fontWeight: 500,
        lineHeight: 1.05,
        marginBottom: 40,
        display: 'block',
        letterSpacing: '-0.72px',
        fontSize: 36,
    },
    h4: {
        fontFamily: ['TWK Everett', 'sans-serif'].join(','),
        fontWeight: 500,
        lineHeight: 1.05,
        marginBottom: 32,
        display: 'block',
        letterSpacing: '-0.24px',
        fontSize: 24,
    },
};
