import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { RENT_EMAILS } from 'frontend-shared-modules/constants';
import { RentRoles } from 'frontend-shared-modules/types';

import { AppConfig } from '../../App/AppConfig';
import { ExternalRoutes, GlobalRoutes } from '../../Routes/GlobalRoutes';
import { useAppInitConfigContext } from '../AppInitConfig/useAppInitConfigContext';

// @ts-ignore
import assetsBBB from './assets/bbb.svg';
// @ts-ignore
import assetsSOC from './assets/soc@2x.png';
import { Download } from './Download';
import { SocialLinks } from './SocialLinks';
import {
    Col,
    ColContent,
    ColSideGroup,
    ColTitle,
    ContentWrapper,
    CopyrightDesktop,
    CopyrightMobile,
    DownloadAppButton,
    FeaturesDesktop,
    FeaturesMobile,
    Logo,
    Row,
    Wrapper,
} from './style';

const FooterRentApp: React.FC = () => {
    const { rentConfig } = useAppInitConfigContext();
    const landlordHomepage = AppConfig.APP_URL + GlobalRoutes.LANDLORD_HOMEPAGE;
    const renterHomepage = AppConfig.APP_URL + '/';
    const privacyPolicy = AppConfig.APP_URL + GlobalRoutes.PRIVACY_POLICY;
    const termsOfUse = AppConfig.APP_URL + GlobalRoutes.TERMS_OF_USE;
    const FAQLink = AppConfig.APP_URL + GlobalRoutes.FAQ;
    const download = AppConfig.APP_URL + GlobalRoutes.DOWNLOAD_RENT_APP;
    const copyright = `© ${new Date().getFullYear()}, Visible Ideas, Inc.`;

    const isLandlord = rentConfig?.role === RentRoles.LANDLORD;

    return (
        <Wrapper>
            <ContentWrapper>
                <Container maxWidth={false}>
                    <Row>
                        <Col className="main">
                            <Logo>
                                <div className="app">Rent App</div>
                                <div className="visible">by Visible</div>
                            </Logo>
                            <FeaturesDesktop>
                                <Link href="https://www.bbb.org/us/fl/coconut-grove/profile/computer-software/visible-ideas-inc-0633-92033642">
                                    <img alt="bbb logo" src={assetsBBB} />
                                </Link>
                                <Link href="https://www.onelogin.com/learn/what-is-soc-2">
                                    <img alt="soc 2 logo" className="soc" src={assetsSOC} />
                                </Link>
                            </FeaturesDesktop>
                            <CopyrightDesktop>
                                <div>{copyright}</div>
                                <Link href={termsOfUse}>Terms of Use</Link>
                                <Link href={privacyPolicy}>Privacy Policy</Link>
                            </CopyrightDesktop>
                        </Col>
                        <Col className="side">
                            <ColContent>
                                <ColTitle>LEARN MORE</ColTitle>
                                <ColSideGroup>
                                    <Link href={ExternalRoutes.VISIBLE_HOMEPAGE} variant="body1">
                                        About Visible
                                    </Link>
                                    <Link href={FAQLink} variant="body1">
                                        Help & FAQs
                                    </Link>
                                    {isLandlord ? (
                                        <Link href={renterHomepage} variant="body1">
                                            For Renters
                                        </Link>
                                    ) : (
                                        <Link href={landlordHomepage} variant="body1">
                                            For Landlords
                                        </Link>
                                    )}
                                </ColSideGroup>
                            </ColContent>
                        </Col>
                        <Col className="side">
                            <ColContent>
                                <ColTitle>CONTACT US</ColTitle>
                                <ColSideGroup>
                                    <Typography variant="body1">
                                        Text or call: <br />
                                        <a href="tel:+18777493592">1 (877) 749-3592</a>
                                    </Typography>
                                    <Typography variant="body1">
                                        <a href={`mailto:${RENT_EMAILS.SUPPORT}`}>{RENT_EMAILS.SUPPORT}</a>
                                    </Typography>
                                    <Typography variant="body1">
                                        Monday–Friday,
                                        <br /> 8AM–8PM ET
                                    </Typography>
                                </ColSideGroup>
                            </ColContent>
                        </Col>
                        <Col className="side">
                            <ColContent>
                                <ColTitle className="social-helper" />
                                <SocialLinks />
                                {!isLandlord && (
                                    <Box display={{ xs: 'none', md: 'block' }}>
                                        <Download />
                                    </Box>
                                )}
                            </ColContent>
                        </Col>
                        <FeaturesMobile>
                            <div className="links">
                                <Link href="https://www.bbb.org/us/fl/coconut-grove/profile/computer-software/visible-ideas-inc-0633-92033642">
                                    <img alt="bbb logo" src={assetsBBB} />
                                </Link>
                                <Link href="https://www.onelogin.com/learn/what-is-soc-2">
                                    <img alt="soc 2 logo" className="soc" src={assetsSOC} />
                                </Link>
                            </div>
                            <DownloadAppButton href={download} LinkComponent={Link}>
                                Download Rent App
                            </DownloadAppButton>
                        </FeaturesMobile>
                        <CopyrightMobile>
                            <div>{copyright}</div>
                            <Link href={termsOfUse}>Terms of Use</Link>
                            <Link href={privacyPolicy}>Privacy Policy</Link>
                        </CopyrightMobile>
                    </Row>
                </Container>
            </ContentWrapper>
        </Wrapper>
    );
};

export default FooterRentApp;
