import React from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CircleError } from 'frontend-shared-modules/components/Icon/icons/circle-error';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';

export const GenericErrorScreen: React.FC = () => {
    const { rentConfig } = useAppInitConfigContext();
    const { palette } = useTheme();
    const { state } = useLocation();
    const { message, description } = state ?? {};
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: rentConfig?.mfa ? '24px' : undefined,
            }}
        >
            <Box
                mb={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 240,
                    width: 240,
                    borderRadius: '50%',
                    backgroundColor: palette.common.error.background,
                    alignSelf: 'center',
                }}
            >
                <CircleError color={palette.common.error.border} />
            </Box>
            <Typography variant="h3">{message || 'Something went wrong'}</Typography>
            {description ? <Typography variant="body1">{description}</Typography> : null}
        </Box>
    );
};
