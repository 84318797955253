import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const Styled = {
    Button: styled('button')(() => ({
        display: 'flex',
        cursor: 'pointer',
        background: 'transparent',
        padding: 0,
        border: 'none',
        width: 32,
        height: 32,
        borderRadius: '50%',
        overflow: 'hidden',
    })),
    Avatar: styled(Box)(() => ({
        width: 32,
        height: 32,
        borderRadius: '50%',
        overflow: 'hidden',
        svg: {
            width: '100%',
        },
    })),
    Menu: styled(Menu)(({ theme: { palette, breakpoints } }) => ({
        '.menu-paper': {
            width: '100%',
            maxWidth: 'unset',
            borderRadius: 0,
            boxShadow: 'none',
            border: `1px solid ${palette.common.gray.A450}`,
            boxSizing: 'border-box',
            position: 'initial',

            ul: {
                padding: 0,
            },
        },

        [breakpoints.up('md')]: {
            transform: 'translateY(-25px) translateX(25px)',
            '.menu-paper': {
                width: 320,
                borderRadius: 4,
                position: 'absolute',
            },
        },
    })),
    Item: styled(MenuItem)(({ theme: { palette, breakpoints } }) => ({
        padding: '20px 32px',
        gap: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        background: `${palette.text.white} !important`,
        cursor: 'default',

        [breakpoints.up('md')]: {
            padding: 24,
        },

        '&.welcome': {
            background: `${palette.text.white} !important`,
        },

        '&.link-group': {
            borderTop: `1px solid ${palette.common.gray.A450}`,
            flexDirection: 'column',
            gap: 24,
        },

        '&.no-top-padding': {
            paddingTop: 0,
        },

        a: {
            color: palette.common.lightPurple,
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline',
            },
        },

        '& > div': {
            minWidth: 0,
        },

        '& .account-group': {
            display: 'flex',
            lineHeight: 1.2,
            flexDirection: 'column',
            gap: 8,
        },

        '& .avatar': {
            width: 32,
            height: 32,
            borderRadius: '50%',
            overflow: 'hidden',
            flexShrink: 0,
        },

        '& .text-main': {
            fontFamily: 'GT Planar, sans-serif',
            fontSize: 24,
        },

        '& .text-gray': {
            color: palette.text.gray,
        },

        '& .text-purple': {
            color: palette.common.lightPurple,
        },
    })),
};
