import React from 'react';
import { CheckmarkRoundEmpty as CheckmarkRoundEmptyIcon } from 'frontend-shared-modules/components/Icon/icons/checkmarkRoundEmpty';
import { CheckmarkRoundSelected as CheckmarkRoundSelectedIcon } from 'frontend-shared-modules/components/Icon/icons/checkmarkRoundSelected';

import { Styled } from './style';

interface PasswordSuggestionsProps {
    password: string;
}

const RULES = [
    {
        rule: /^.{8,}$/,
        message: 'At least 8 characters',
    },
    {
        rule: /[a-z]/,
        message: 'At least one lowercase letter',
    },
    {
        rule: /[A-Z]/,
        message: 'At least one uppercase letter',
    },
    {
        rule: /[0-9!@#$%^&*]/,
        message: 'A number or special character (!@#$%^&*)',
    },
];
export const PasswordSuggestions: React.FC<PasswordSuggestionsProps> = ({ password }) => {
    return (
        <Styled.Container>
            {RULES.map(({ rule, message }) => {
                const isMatch = rule.test(password);
                return (
                    <Styled.Item className={isMatch ? 'green' : ''} key={message}>
                        {isMatch ? <CheckmarkRoundSelectedIcon size={16} /> : <CheckmarkRoundEmptyIcon size={16} />}
                        {message}
                    </Styled.Item>
                );
            })}
        </Styled.Container>
    );
};
