import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { RentRoles } from 'frontend-shared-modules/types';

import { AppConfig } from '../../App/AppConfig';
import { GlobalRoutes } from '../../Routes/GlobalRoutes';
import { useAppInitConfigContext } from '../AppInitConfig/useAppInitConfigContext';

import { Logo } from './Logo/Logo';
import { NavMenu } from './Nav/NavMenu';
import { Group, SpaceMaker, UserRole, Wrapper } from './style';
import { UserMenu } from './UserMenu';

const Header: React.FC = () => {
    const { rentConfig } = useAppInitConfigContext();
    const isLandlord = rentConfig?.role === RentRoles.LANDLORD;
    const hideMenu = rentConfig?.authorizedHeader && rentConfig?.email;
    const homeLink = isLandlord ? AppConfig.APP_URL + GlobalRoutes.LANDLORD_HOMEPAGE : AppConfig.APP_URL;

    return (
        <Wrapper appId={AppConfig.APP_ID}>
            <Container maxWidth={false}>
                <Group>
                    <SpaceMaker>
                        <Box alignItems="center" display="flex" gap={1}>
                            <Logo href={homeLink} />
                            {rentConfig?.role === RentRoles.LANDLORD && <UserRole>FOR LANDLORDS</UserRole>}
                        </Box>
                    </SpaceMaker>
                    {rentConfig?.navigation && <NavMenu />}
                    {<SpaceMaker className="right">{!hideMenu && <UserMenu />}</SpaceMaker>}
                </Group>
            </Container>
        </Wrapper>
    );
};

export default Header;
