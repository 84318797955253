import auth0 from 'auth0-js';

export interface Auth0ContextInterface {
    webAuth?: auth0.WebAuth;
}

const params = Object.assign(
    {
        overrides: {
            __tenant: LOGIN_PAGE_CONFIG?.auth0Tenant,
            __token_issuer: LOGIN_PAGE_CONFIG?.authorizationServer.issuer,
        },
        domain: LOGIN_PAGE_CONFIG?.auth0Domain,
        clientID: LOGIN_PAGE_CONFIG?.clientID,
        redirectUri: LOGIN_PAGE_CONFIG?.callbackURL,
        responseType: 'code',
    },
    LOGIN_PAGE_CONFIG?.internalOptions,
);

export const INITIAL_VALUE: Auth0ContextInterface = {
    webAuth: LOGIN_PAGE_CONFIG ? new auth0.WebAuth(params) : undefined,
};
