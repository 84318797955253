import { Logger } from 'frontend-shared-modules/services/logger';

export const safeExecute = function (func: (...params: ReadonlyArray<any>) => any, args: ReadonlyArray<any> = []) {
    let result = null;

    try {
        result = func(...args);
    } catch (err: any) {
        Logger.warn(err);
    }

    return result;
};
