import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Routes } from '../../Routes/routesConfig';

export const useNavigateToError = () => {
    const navigate = useNavigate();

    return useCallback(({ message, description }: { message?: string; description?: string }) => {
        navigate(Routes.ERROR, {
            state: {
                message,
                description,
            },
        });
    }, []);
};
