import React, { useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Auth0Error } from 'auth0-js';
import { FORM_ERRORS } from 'frontend-shared-modules/validation/general';
import { Routes } from 'Routes/routesConfig';
import { useAuth0Context } from 'Shared/Auth0Context/useAuth0Context';
import { ErrorMessage } from 'Shared/ErrorMessage/ErrorMessage';
import { useLogError } from 'Shared/hooks/useLogError';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import { PasswordField } from 'Shared/PasswordField/PasswordField';
import * as yup from 'yup';

interface ILoginFormValue {
    email: string;
    password: string;
}

const EmailField = styled(TextField)<{ confirmationScreen?: boolean }>(({ confirmationScreen }) => ({
    ['& input']: {
        WebkitBoxShadow: confirmationScreen ? '0 0 0 1000px white inset' : 'none',
        boxShadow: confirmationScreen ? '0 0 0 1000px white inset' : 'none',
    },
}));

export const SignInForm: React.FC<{ mfaConfirmation?: boolean; email?: string }> = ({ mfaConfirmation, email }) => {
    const { webAuth } = useAuth0Context();
    const [error, setError] = React.useState<Auth0Error | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<any>({});
    const { logError } = useLogError();

    const { authButtonVariant } = useRenterScoreTheme();
    const confirmationScreen = !!(mfaConfirmation && email);

    const [formData, setFormData] = React.useState<ILoginFormValue>({
        email: confirmationScreen ? email : '',
        password: '',
    });

    const schema = yup
        .object({
            email: yup.string().trim().email(FORM_ERRORS.email.invalid).required(FORM_ERRORS.email.required),
            password: yup.string().required(FORM_ERRORS.password.required),
        })
        .required();

    const handleLogin = useCallback(async () => {
        setIsLoading(true);
        setErrors({});

        try {
            await schema.validate(formData, { abortEarly: false });
            webAuth?.login(
                {
                    realm: 'Username-Password-Authentication',
                    username: formData.email,
                    password: formData.password,
                    responseType: 'code',
                },
                (err) => {
                    setIsLoading(false);
                    logError(err);
                    setError(err);
                },
            );
        } catch (validationErrors) {
            // @ts-ignore
            const errors = validationErrors.inner.reduce((allErrors, currentError) => {
                return { ...allErrors, [currentError.path]: currentError.message };
            }, {});
            setErrors(errors);
            setIsLoading(false);
        }
    }, [formData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (confirmationScreen && name === 'email') return;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        setError(null);
    }, [formData.email, formData.password, setError]);

    return (
        <>
            {error ? <ErrorMessage>{error.description}</ErrorMessage> : null}
            <Box mt={2}>
                <EmailField
                    fullWidth
                    confirmationScreen={confirmationScreen}
                    disabled={confirmationScreen}
                    error={!!errors.email}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            borderRadius: '8px 8px 0 0',
                            marginBottom: '-1px',
                        },
                    }}
                    label="Email Address"
                    name={'email'}
                    type="text"
                    value={formData.email}
                    variant="filled"
                    onChange={handleChange}
                />
                <PasswordField
                    fullWidth
                    error={!!errors.password}
                    InputProps={{
                        style: {
                            borderRadius: '0 0 8px 8px',
                        },
                    }}
                    label="Password"
                    name={'password'}
                    value={formData.password}
                    variant="filled"
                    onChange={handleChange}
                />
            </Box>
            <Box mt={2}>
                <Button disabled={isLoading} type="submit" variant={authButtonVariant} onClick={handleLogin}>
                    {isLoading ? <CircularProgress /> : 'Continue'}
                </Button>
            </Box>
            {!mfaConfirmation && (
                <Link
                    align="center"
                    component={RouterLink}
                    state={{ email: formData.email }}
                    to={Routes.REQUEST_RESET_PASSWORD}
                    variant="body1"
                >
                    Forgot password?
                </Link>
            )}
        </>
    );
};
