import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { SocialLinks } from 'Shared/SocialLinks';

import { AppConfig } from '../../App/AppConfig';
import { GlobalRoutes } from '../../Routes/GlobalRoutes';

import { ContentWrapper, Copy, LeftWrapper, Logo, MobileCopyright, NavBlock, NavBox, Wrapper } from './style';

const FooterPropertyApp: React.FC = () => {
    const copyright = `© ${new Date().getFullYear()}, Visible Ideas, Inc.`;
    const FAQLink = AppConfig.APP_URL + GlobalRoutes.FAQ;
    const contactUsLink = AppConfig.APP_URL + GlobalRoutes.CONTACT_US_PROPERTY;
    const privacyPolicy = AppConfig.APP_URL + GlobalRoutes.PRIVACY_POLICY;
    const termsOfUse = AppConfig.APP_URL + GlobalRoutes.TERMS_OF_USE;

    return (
        <Wrapper>
            <ContentWrapper>
                <Container>
                    <Grid container columnSpacing={{ lg: 4 }} justifyContent="space-between">
                        <Grid item md={4} xs={12}>
                            <LeftWrapper>
                                <Logo>
                                    <div className="app">{AppConfig.APP_NAME}</div>
                                    <div className="visible">by Visible</div>
                                </Logo>
                                <SocialLinks className={'social-links'} />
                                <Copy className="lg">{copyright}</Copy>
                            </LeftWrapper>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <NavBox>
                                <NavBlock>
                                    <ul>
                                        <li>
                                            <Link href={AppConfig.MAIN_APP_URL} variant="body1">
                                                About Visible
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={FAQLink} variant="body1">
                                                Help & FAQs
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={contactUsLink} variant="body1">
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </NavBlock>
                                <NavBlock>
                                    <ul>
                                        <li>
                                            <Link href={privacyPolicy} variant="body1">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={termsOfUse} variant="body1">
                                                Terms of Use
                                            </Link>
                                        </li>
                                    </ul>
                                </NavBlock>
                            </NavBox>
                        </Grid>
                        <MobileCopyright className={'xs'}>
                            <SocialLinks noMargin={true} />
                            <Copy className="xs">{copyright}</Copy>
                        </MobileCopyright>
                    </Grid>
                </Container>
            </ContentWrapper>
        </Wrapper>
    );
};

export default FooterPropertyApp;
