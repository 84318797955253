import React from 'react';

import { Styled } from './style';

export const SignUpSuccess = () => {
    return (
        <Styled.Container>
            <Styled.Title>Check Your email</Styled.Title>
        </Styled.Container>
    );
};
