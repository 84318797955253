import React from 'react';

import { AppConfig } from '../../App/AppConfig';
import { AppIdentifiers } from '../../types/AppIdentifiers';

import { Social } from './style';

interface IProps {
    className?: string;
    noMargin?: boolean;
}

export const SocialLinks: React.FC<IProps> = ({ className, noMargin }) => {
    return (
        <Social.Wrapper className={className || ''} noMargin={noMargin}>
            {AppConfig.APP_ID === AppIdentifiers.RENT ? (
                <>
                    <Social.Link href="https://www.instagram.com/rentapp/" target="_blank">
                        <svg fill="none" height="24" width="25" xmlns="http://www.w3.org/2000/svg">
                            <path
                                clipRule="evenodd"
                                d="M8 .75A6.75 6.75 0 0 0 1.25 7.5v9A6.75 6.75 0 0 0 8 23.25h9a6.75 6.75 0 0 0 6.75-6.75v-9A6.75 6.75 0 0 0 17 .75H8ZM2.75 7.5C2.75 4.6 5.1 2.25 8 2.25h9c2.9 0 5.25 2.35 5.25 5.25v9c0 2.9-2.35 5.25-5.25 5.25H8a5.25 5.25 0 0 1-5.25-5.25v-9ZM20 6.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm-7.5 1.5a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5ZM6.75 12a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1-11.5 0Z"
                                fill="#fff"
                                fillRule="evenodd"
                            />
                        </svg>
                    </Social.Link>
                    <Social.Link href="https://twitter.com/RentApp" target="_blank">
                        <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M.057 1.034 9.162 13.13 0 22.966h2.062l8.022-8.61 6.48 8.61h7.018L13.965 10.19l8.528-9.155h-2.062l-7.387 7.93-5.97-7.93H.058ZM3.09 2.543h3.224l14.235 18.914h-3.224L3.09 2.543Z"
                                fill="#fff"
                            />
                        </svg>
                    </Social.Link>
                    <Social.Link href="https://www.tiktok.com/@rentapp" target="_blank">
                        <svg fill="none" height="24" width="25" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.5 1H13v15.5c0 1.5-1.5 3-3 3s-3-.5-3-3c0-2 1.899-3.339 3.5-3V10c-6.12 0-7 5-7 6.5S4.477 23 10 23c4.522 0 6.5-3.5 6.5-6V8c1.146 1.018 2.922 1.357 5 1.5V6c-3.017 0-5-2.654-5-5Z"
                                fill="#fff"
                            />
                        </svg>
                    </Social.Link>
                </>
            ) : (
                <Social.Link href="https://twitter.com/VSBL_XYZ" target="_blank">
                    <svg fill="none" height="24" width="25" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.5 19c15.617 6.062 20.038-5.025 19.905-10.5 1.268-.344 2.095-1.208 2.095-2.094-1.048.55-1.595.394-2.119 0 1.128-.768 1.071-1.863 1.071-2.619-.527.277-1.503.96-2.619 1.048-.745-1.166-2.619-2.095-5.238-1.048-2.619 1.048-3.143 3.842-2.619 5.238-3.352 0-7.333-3.492-8.905-5.238-1.883 2.688.5 5.353 1.572 6.286-.715.214-1.572 0-2.095-.524 0 2.991 2.619 4.19 3.666 4.715H4.12c0 2.095 2.444 2.968 3.667 3.142-.838.838-4.215 1.596-6.286 1.596L1.5 19Z"
                            fill="#fff"
                        />
                    </svg>
                </Social.Link>
            )}
        </Social.Wrapper>
    );
};
