import React from 'react';
import OTPInput from 'react-otp-input';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const OtpItem = styled('input')<{ isError?: boolean; highlightOnError?: boolean }>(({ theme, isError }) => ({
    borderRadius: 8,
    fontSize: 40,
    textAlign: 'center',
    border: isError ? `1px solid ${theme.palette.common.darkRed}` : `1px solid ${theme.palette.common.gray.A450}`,
    fontFamily: 'GT America Mono',
    padding: 0,

    width: 57,
    height: 95,

    [theme.breakpoints.down('mobileL')]: {
        fontSize: 24,
        width: 42,
        height: 70,
    },
    '&:focus': {
        outline: 'none',
        border: `2px solid ${theme.palette.common.lightPurple}`,
    },

    '&:disabled': {
        backgroundColor: theme.palette.common.gray.A50,
    },
}));

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: 'fit-content',
}));

const InputWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',

    '& input[type=number]': {
        MozAppearance: 'textfield',
    },

    '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
    },
    '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
}));

const C = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.darkRed,
}));

type IProps = {
    length: number;
    value: string;
    onChange: (value: string) => void;
    error?: string | null;
    highlightOnError?: boolean;
};

export const OtpCodeInput: React.FC<IProps> = ({ length, value, onChange, error, highlightOnError = true }) => {
    return (
        <C>
            <Wrapper>
                <InputWrapper>
                    <OTPInput
                        containerStyle={{
                            gap: 8,
                            width: '100%',
                            justifyContent: 'center',
                        }}
                        inputType="number"
                        numInputs={length}
                        renderInput={(props) => {
                            return <OtpItem {...props} isError={!!error && highlightOnError} style={undefined} />;
                        }}
                        shouldAutoFocus={true}
                        value={value}
                        onChange={onChange}
                    />
                </InputWrapper>
                {error && <ErrorText>{error}</ErrorText>}
            </Wrapper>
        </C>
    );
};
