export const getErrorMessage = (error?: { errorCode: string; message: string }) => {
    if (!error) {
        return '';
    }

    switch (error.errorCode) {
        case 'invalid_otp':
            return 'This code you entered is incorrect. Please try again.';
        case 'too_many_sms':
            return 'You have exceeded the maximum number of SMS attempts. Please try again later.';
        default:
            return error.message;
    }
};
