import { styled } from '@mui/material/styles';

export const Nav = styled('nav')(({ theme: { palette, breakpoints } }) => ({
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
        display: 'none',
    },

    ul: {
        display: 'flex',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        gap: 40,

        a: {
            fontFamily: 'TWK Everett',
            fontWeight: 500,
            color: palette.text.gray,
            textDecoration: 'none',
            transition: 'color .3s ease',
        },

        'li.active a': {
            color: palette.common.lightPurple,
        },
    },

    '.disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));
