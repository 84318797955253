import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import auth0GuardianJS from 'auth0-guardian-js';
import { Auth } from 'Screens/Mfa/Auth/Auth';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';

import { useNavigateToError } from '../../Shared/hooks/useNavigateToError';
import { useMfaTransactionContext } from '../../Shared/MfaTransactionContext/useMfaTransactionContext';

import { Enroll } from './Enrolment/Enroll';
import { RecoveryCode } from './Recovery/RecoveryCode';

export const Mfa: React.FC = () => {
    const [guardian, setGuardian] = useState();
    const recoveryCodeRef = React.useRef<string>();
    const [authPayload, setAuthPayload] = useState<any>();
    const { transaction, setTransaction } = useMfaTransactionContext();
    const showError = useNavigateToError();
    const { rentConfig } = useAppInitConfigContext();
    const getErrorMessage = (error: Error | string) => (typeof error === 'string' ? error : error.message);

    const postLoginAction = useCallback((authPayload?: any) => {
        if (!postActionURL) return;

        // @ts-ignore
        auth0GuardianJS.formPostHelper(postActionURL, authPayload);
    }, []);

    useEffect(() => {
        setGuardian(auth0GuardianJS(MFA_PAGE_CONFIG));
    }, []);

    useEffect(() => {
        if (!guardian) return;

        // @ts-ignore
        guardian.start((err: Error | string, guardianTransaction: any) => {
            if (err) {
                showError({ message: getErrorMessage(err) });
            }
            setTransaction(guardianTransaction);
        });
    }, [guardian]);

    useEffect(() => {
        if (!transaction) return;

        // @ts-ignore
        transaction.on('enrollment-complete', (payload: any) => {
            if (payload.recoveryCode) {
                // recoveryCodeRef.current = payload.recoveryCode;
            }
        });

        transaction.on('timeout', function () {
            if (rentConfig?.mfa) {
                // return back on timeout for web
                // we have some ugly logic with timeout in the mobile app
                return history.back();
            }
        });

        transaction.on('error', function (err: Error | string) {
            // not redirect to error page if the error is invalid_otp
            // @ts-ignore
            if (typeof err !== 'string' && err?.errorCode === 'invalid_otp') {
                return;
            }
        });
        transaction.on('enrollment-complete', function (payload: any) {
            if (payload.recoveryCode) {
                // todo check recovery code logic
            }
        });

        transaction.on('auth-response', (payload: any) => {
            if (recoveryCodeRef.current || payload.recoveryCode) {
                setAuthPayload(payload);
                return;
            }

            postLoginAction(payload);
        });
    }, [transaction, postLoginAction, setAuthPayload]);

    const render = useCallback(() => {
        if (recoveryCodeRef.current) {
            return (
                <RecoveryCode
                    authPayload={authPayload}
                    recoveryCode={recoveryCodeRef.current}
                    onContinue={postLoginAction}
                />
            );
        }
        return transaction.isEnrolled() ? <Auth /> : <Enroll />;
    }, [authPayload, postLoginAction, transaction]);

    if (!transaction) {
        return null;
    }

    return render();
};
