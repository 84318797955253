import React, { useCallback, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BackBtn } from 'Components/BackBtn';
import { OtpCodeInput } from 'Components/OtpCodeInput';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { Title, Wrapper } from 'Screens/Mfa/style';
import { useLogError } from 'Shared/hooks/useLogError';
import { useMobileMFA } from 'Shared/hooks/useMobileMFA';
import { getErrorMessage } from 'Utils/error';

interface CodeInputProps {
    onResendPhone: (phone: string, onError: (error: any) => void) => void;
    onSubmit: (code: string, onError: (error: any) => void) => void;
    phone: string;
    initialSend?: boolean;
    handleBack: () => void;
}

const SubmitContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    flex: 1,
    justifyContent: 'end',
});

export const CodeInputScreen: React.FC<CodeInputProps> = ({
    onSubmit,
    phone,
    onResendPhone,
    initialSend,
    handleBack,
}) => {
    const isMobileMfa = useMobileMFA();
    const { palette } = useTheme();
    const [error, setError] = React.useState<any>();
    const [code, setCode] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState(false);
    const { logError } = useLogError();
    const highlightOnError = useMemo(() => {
        return error?.errorCode !== 'too_many_sms';
    }, [error]);

    const onCodeSubmit = useCallback(() => {
        setIsLoading(true);
        onSubmit(code, (error) => {
            setIsLoading(false);
            setError(error);
            logError(error);
        });
    }, [onSubmit, code]);

    useEffect(() => {
        if (phone && initialSend) {
            onResendPhone(phone, (error) => {
                setError(error);
                logError(error);
                setIsLoading(false);
            });
        }
    }, [phone, initialSend]);

    const onResendClick = useCallback(() => {
        onResendPhone(phone, (error) => {
            setError(error);
            logError(error);
        });
    }, [phone, onResendPhone]);

    const handleBackBtnClick = useCallback(() => {
        setError(null);
        handleBack();
    }, [handleBack]);

    const handleCodeChange = useCallback((code: string) => {
        if (code.length < 6) {
            setError(null);
        }
        setCode(code);
    }, []);

    return (
        <Wrapper>
            {isMobileMfa && <BackBtn handleBack={handleBackBtnClick} />}
            {isMobileMfa ? (
                <Title>Confirm Your Code</Title>
            ) : (
                <ScreenHeader withBackButton onBackButtonClick={handleBack}>
                    Confirm Your Code
                </ScreenHeader>
            )}
            <Typography alignSelf={'flex-start'} variant="body1">{`Enter the code sent to ${phone}:`}</Typography>
            <OtpCodeInput
                error={getErrorMessage(error)}
                highlightOnError={highlightOnError}
                length={6}
                value={code}
                onChange={handleCodeChange}
            />
            <SubmitContainer>
                <Typography align="center" color={palette.text.gray} variant="body1">
                    Didn't receive a code?{' '}
                    <Link href="#" onClick={onResendClick}>
                        Resend
                    </Link>
                </Typography>

                <Button
                    fullWidth
                    disabled={isLoading || code.length !== 6}
                    variant={'dark-purple'}
                    onClick={onCodeSubmit}
                >
                    Confirm
                </Button>
            </SubmitContainer>
        </Wrapper>
    );
};
