import { buttonClasses } from '@mui/material';

import { mediaQueries } from './mediaQueries';
import { palette } from './palette';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        [key: string]: true;
    }
}

const defaultButtonStyles = {
    fontFamily: 'TWK Everett',
    fontWeight: 500,
    padding: '22px 24px',
    letterSpacing: '0.01em',
    boxShadow: 'none',
    fontSize: 16,
    lineHeight: '20px',
    borderRadius: 8,
    height: 64,
    textTransform: 'none' as const,
    display: 'flex',
    justifyContent: 'center',

    '&.MuiButton-sizeSmall': {
        height: 40,
        fontSize: 12,
        padding: '8px 24px',
        borderRadius: 4,
    },

    '.button-icon': {
        position: 'absolute',

        '&.end': {
            right: 24,
        },

        '&.start': {
            left: 24,
        },
    },
};

export const overrides = {
    MuiButton: {
        defaultProps: {
            variant: 'outlined',
            fullWidth: true,
        },
        variants: [
            {
                props: { variant: 'outlined' },
                style: {
                    border: `1px solid ${palette.common.gray.A450}`,
                    background: palette.common.white,
                    color: palette.text.black,
                    ...defaultButtonStyles,

                    ':hover': {
                        boxShadow: 'none',
                        color: palette.common.black,
                    },

                    '& .MuiButton-startIcon': {
                        position: 'absolute',
                        left: '24px',
                        margin: 0,
                    },
                },
            },
            {
                props: { variant: 'contained' },
                style: {
                    border: 'none',
                    background: palette.common.dark.A100,
                    color: palette.text.white,
                    ...defaultButtonStyles,

                    ':hover': {
                        border: 'none',
                        background: palette.common.dark.A200,
                        boxShadow: 'none',
                    },

                    ':disabled': {
                        background: palette.common.gray.A450,
                        color: palette.secondary.main,
                    },
                },
            },
            {
                props: { variant: 'dark-purple' },
                style: {
                    border: 'none',
                    background: palette.darkPurple,
                    color: palette.secondary.main,
                    ...defaultButtonStyles,

                    ':hover': {
                        border: 'none',
                        background: palette.darkPurpleHover,
                        boxShadow: 'none',
                    },

                    ':disabled': {
                        background: palette.common.gray.A450,
                        color: palette.secondary.main,
                    },
                },
            },
            {
                props: { variant: 'dark' },
                style: {
                    border: '1px solid #333',
                    background: 'rgba(255, 255, 255, 0.10)',
                    color: palette.secondary.main,
                    ...defaultButtonStyles,
                    ':hover': {
                        border: '1px solid transparent',
                        color: palette.secondary.main,
                        background: 'rgba(255, 255, 255, 0.15)',
                        boxShadow: 'none',
                    },
                    [`& .${buttonClasses.startIcon}`]: {
                        position: 'absolute',
                        left: 24,
                    },

                    ':disabled': {
                        border: 'none',
                        background: palette.common.gray.A450,
                        color: palette.secondary.main,
                    },
                },
            },
            {
                props: { variant: 'black' },
                style: {
                    border: 'transparent',
                    background: palette.common.dark.A100,
                    color: palette.secondary.main,
                    ...defaultButtonStyles,
                    ':hover': {
                        border: '1px solid transparent',
                        color: palette.secondary.main,
                        background: palette.common.dark.A200,
                        boxShadow: 'none',
                    },
                    [`& .${buttonClasses.startIcon}`]: {
                        position: 'absolute',
                        left: 24,
                    },

                    ':disabled': {
                        border: 'transparent',
                        background: palette.common.gray.A450,
                        color: palette.secondary.main,
                    },
                },
            },
            {
                props: { variant: 'blue' },
                style: {
                    border: 'transparent',
                    background: '#2B23C0',
                    color: palette.secondary.main,
                    ...defaultButtonStyles,
                    ':hover': {
                        border: '1px solid transparent',
                        color: palette.common.white,
                        background: '#18119E',
                        boxShadow: 'none',
                    },
                    [`& .${buttonClasses.startIcon}`]: {
                        position: 'absolute',
                        left: 24,
                    },

                    ':disabled': {
                        border: 'transparent',
                        background: '#BFBFBF',
                        color: palette.common.white,
                    },
                },
            },
        ],
    },
    MuiTextField: {
        defaultProps: {
            InputProps: { disableUnderline: true },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                zIndex: 2,
                color: palette.common.gray.A180,
                left: 12,
                fontSize: 16,
                lineHeight: '24px',
                transform: 'translate(12px, 20px) scale(1)',
                pointerEvents: 'none' as const,
                '&.Mui-focused': {
                    color: palette.common.gray.A180,
                    left: 12,
                },
                '&.Mui-error': {
                    color: palette.error.main,
                },
            },
            shrink: {
                fontSize: 11,
                transform: 'translate(12px, 7px) scale(0.75)',
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                height: 64,
                backgroundColor: palette.secondary.main,
                padding: '8px 24px 10px',
            },
            input: {
                padding: '10px 0 0 0 !important',
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                borderRadius: 8,
                backgroundColor: palette.secondary.main,
                border: `1px solid ${palette.common.gray.A450}`,
                '&:hover': {
                    backgroundColor: palette.secondary.main,
                    borderColor: palette.primary.main,
                    zIndex: 1,
                },
                '&.Mui-focused': {
                    backgroundColor: palette.secondary.main,
                },
                '&.Mui-error': {
                    borderColor: palette.error.main,
                    backgroundColor: palette.error.background,
                },
                '&.Mui-disabled': {
                    backgroundColor: palette.text.white,
                    borderColor: palette.common.gray.A50,
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: 0,
                '&.Mui-focused': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                        zIndex: 1,
                    },
                },
                '&.Mui-error': {
                    '.MuiOutlinedInput-notchedOutline': {
                        backgroundColor: palette.error.background,
                    },
                },
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontStyle: 'italic',
                fontSize: 11,
                lineHeight: '24px',
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                padding: '0 32px',
                [mediaQueries.mobileFirst.sm]: {
                    padding: '0 32px',
                },
                [mediaQueries.mobileFirst.xl]: {
                    padding: '0 64px',
                },
                '&.MuiContainer-maxWidthLg': {
                    [mediaQueries.mobileFirst.xl]: {
                        maxWidth: '1440px',
                    },
                },
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: '#5E42FA',
                textDecorationColor: '#5E42FA',

                '&.white': {
                    color: palette.common.white,
                    textDecorationColor: palette.common.white,
                },
            },
        },
    },
};
