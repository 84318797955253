import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Auth0Error } from 'auth0-js';
import { FORM_ERRORS } from 'frontend-shared-modules/validation/general';
import { useLogError } from 'Shared/hooks/useLogError';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import * as yup from 'yup';

import { ScreenHeader } from '../../Components/ScreenHeader/ScreenHeader';
import { useAuth0Context } from '../../Shared/Auth0Context/useAuth0Context';
import { ErrorMessage } from '../../Shared/ErrorMessage/ErrorMessage';

import { SuccessScreen } from './SuccessScreen';

interface IRequestPasswordResetFormValue {
    email: string;
}

export const RequestPasswordReset: React.FC = () => {
    const { state } = useLocation();
    const { webAuth } = useAuth0Context();
    const { authButtonVariant } = useRenterScoreTheme();
    const [error, setError] = React.useState<Auth0Error | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const { logError } = useLogError();

    const onRequestReset = useCallback(({ email }: { email: string }) => {
        webAuth?.changePassword(
            {
                connection: 'Username-Password-Authentication',
                email,
            },
            (err) => {
                if (!err) {
                    return setIsSuccess(true);
                }
                setError(err);
                logError(err);
                //TODO handleError
            },
        );
    }, []);

    const schema = yup
        .object({
            email: yup.string().email(FORM_ERRORS.email.invalid).required(FORM_ERRORS.email.required),
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IRequestPasswordResetFormValue>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: state?.email || '',
        },
    });

    if (isSuccess) {
        return <SuccessScreen resend={setIsSuccess} />;
    }

    return (
        <>
            <ScreenHeader>Reset Password</ScreenHeader>
            <Typography variant="body1">Enter your email to reset your password.</Typography>
            {error ? <ErrorMessage>{error.description}</ErrorMessage> : null}
            <form onSubmit={handleSubmit(onRequestReset)}>
                <Box>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!errors.email?.message}
                                helperText={errors.email?.message}
                                label="Email Address"
                                type="text"
                                variant="filled"
                            />
                        )}
                    />
                </Box>
                <Box mt={2}>
                    <Button fullWidth color="primary" type="submit" variant={authButtonVariant}>
                        Reset Password
                    </Button>
                </Box>
            </form>
        </>
    );
};
