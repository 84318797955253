import React from 'react';

export const MfaTransactionContext = React.createContext<{
    transaction?: any;
    setTransaction: (transaction: any) => void;
}>({
    setTransaction: () => {
        return;
    },
});
