import { createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createTheme({
    palette,
    components: overrides,
    typography,
    breakpoints,
});
