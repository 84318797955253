import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import * as yup from 'yup';

import { confirmResetPassword, IResetFormData } from '../../API/confirmResetPassword';
import { AppConfig } from '../../App/AppConfig';
import { ScreenHeader } from '../../Components/ScreenHeader/ScreenHeader';
import { ErrorMessage } from '../../Shared/ErrorMessage/ErrorMessage';
import { PasswordField } from '../../Shared/PasswordField/PasswordField';
import { PasswordSuggestions } from '../../Shared/PasswordSuggestions/PasswordSuggestions';

import { SuccessScreen } from './SuccessScreen';

export const ResetPassword: React.FC = () => {
    const { authButtonVariant } = useRenterScoreTheme();
    const [error, setError] = React.useState<Record<string, string | number> | null>(null);
    const [redirectTo, setRedirectTo] = React.useState<string | null>(null);
    const onRequestReset = useCallback(async (data: IResetFormData) => {
        const resp = await confirmResetPassword(data);

        if (resp.status >= 400 || !resp.success) {
            setError(resp);
        } else {
            if (resp.result_url) {
                const isAndroid = /Android/i.test(navigator.userAgent);
                const urlString = isAndroid ? `${AppConfig.APP_URL}/login` : resp.result_url;
                const url = new URL(urlString);
                setRedirectTo(url.toString());
                setTimeout(() => {
                    window.location.replace(url);
                }, 5000);
            }
        }
    }, []);

    const schema = yup
        .object({
            newPassword: yup
                .string()
                .required()
                .matches(/^(?=.*[\d!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'The password is too weak'),
            confirmNewPassword: yup
                .string()
                .required('Please retype your password.')
                .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<IResetFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            newPassword: '',
            confirmNewPassword: '',
        },
    });

    const password = watch('newPassword', '');

    if (redirectTo) {
        return <SuccessScreen redirectUrl={redirectTo} />;
    }
    return (
        <>
            <ScreenHeader>Create a New Password</ScreenHeader>
            <Typography variant="body1">Enter the new password you’d like for your account.</Typography>
            {error && <ErrorMessage>{error.description}</ErrorMessage>}
            <form onSubmit={handleSubmit(onRequestReset)}>
                <Box>
                    <Controller
                        control={control}
                        name="newPassword"
                        render={({ field }) => (
                            <PasswordField
                                {...field}
                                fullWidth
                                error={!!errors.newPassword?.message}
                                helperText={errors.newPassword?.message}
                                label="Password"
                                type="password"
                                variant="filled"
                            />
                        )}
                    />
                </Box>
                <Box mt={2}>
                    <Controller
                        control={control}
                        name="confirmNewPassword"
                        render={({ field }) => (
                            <PasswordField
                                {...field}
                                fullWidth
                                error={!!errors.confirmNewPassword?.message}
                                helperText={errors.confirmNewPassword?.message}
                                label="Confirm Password"
                                type="password"
                                variant="filled"
                            />
                        )}
                    />
                </Box>
                <Box mt={2}>
                    <PasswordSuggestions password={password} />
                </Box>
                <Box mt={2}>
                    <Button fullWidth color="primary" type="submit" variant={authButtonVariant}>
                        Set new password
                    </Button>
                </Box>
            </form>
        </>
    );
};
