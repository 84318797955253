import { useEffect } from 'react';
import { PropertyDataFull } from 'frontend-shared-modules/types';

import { useLocalStorageState } from '../hooks/useLocalState';

export const CONFIG_STORAGE_KEY = 'authAppUserConfig';

export interface IAppConfig {
    rentConfig?: {
        role?: 'RENTER' | 'LANDLORD';
        navigation?: string;
        mapLongitude?: string;
        mapLatitude?: string;
        mapColor?: 'pink' | 'dark';
        mapStyle?: string;
        mfa?: string;
        email?: string;
        pwd?: string;
        confirmCredentials?: string;
        phone?: string;
        authorizedHeader?: string;
        loginTitle?: string;
        ddRumSessionId?: string;
        // todo move to separated config once move renter score to separated domain
        renterScoreRole?: string;
    };
    nftConfig?: {
        address?: string;
        property?: PropertyDataFull;
    };
}

export const useInitAppConfig = (): IAppConfig => {
    const [value, setStorageValue] = useLocalStorageState<IAppConfig>(CONFIG_STORAGE_KEY, {});

    useEffect(() => {
        if (window.location.hash) {
            const hash = window.location.hash.replace('#', '');
            const items = hash.split('&').reduce((result, item = '') => {
                const [keyWithPrefix = '', value = ''] = item.split('=');
                const [prefix = '', key = ''] = keyWithPrefix.split(':');

                switch (prefix) {
                    case 'property': {
                        return {
                            ...result,
                            nftConfig: {
                                ...result.nftConfig,
                                [key]:
                                    key === 'property'
                                        ? JSON.parse(decodeURIComponent(value))
                                        : decodeURIComponent(value),
                            },
                        };
                    }
                    case 'rent': {
                        return {
                            ...result,
                            rentConfig: {
                                ...result.rentConfig,
                                [key]: value,
                            },
                        };
                    }
                    default: {
                        return result;
                    }
                }
            }, {} as IAppConfig) as IAppConfig;

            setStorageValue(items);
        }
    }, [window.location.hash, setStorageValue]);

    return value;
};
