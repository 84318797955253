import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BackBtn } from 'Components/BackBtn';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { Copy as CopyIcon } from 'frontend-shared-modules/components/Icon/icons/copy';
import { QRCodeSVG } from 'qrcode.react';
import { OtpStages } from 'Screens/Mfa/Enrolment/OTP/index';
import { AuthenticatorCode, AuthenticatorCodeContainer, Title, Wrapper } from 'Screens/Mfa/style';
import { useMobileMFA } from 'Shared/hooks/useMobileMFA';

type IProps = {
    error: any;
    enroll: any;
    setStage: (stage: OtpStages) => void;
    handleBack: () => void;
};

const CopyElement = styled('div')(() => ({
    cursor: 'pointer',
}));

export const EnrollOtp: React.FC<IProps> = ({ enroll, setStage, handleBack }) => {
    const otpUrl = enroll.getUri();
    const secret = otpUrl.match(/secret=([^&]+)/)[1];
    const isMobileMfa = useMobileMFA();
    const [showTooltip, setShowTooltip] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(secret);
        setShowTooltip(true);
    };

    useEffect(() => {
        if (!showTooltip) return;

        const timeoutID = setTimeout(() => {
            setShowTooltip(false);
        }, 500);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [showTooltip]);

    const handleConfirm = useCallback(() => {
        setStage(OtpStages.CONFIRM);
    }, [setStage]);

    if (!enroll) {
        return null;
    }

    return (
        <Wrapper>
            {isMobileMfa && <BackBtn handleBack={handleBack} />}
            {isMobileMfa ? (
                <Title>2 - Step Verification</Title>
            ) : (
                <ScreenHeader withBackButton onBackButtonClick={handleBack}>
                    Set up 2-Step Verification
                </ScreenHeader>
            )}
            <Typography variant="body1">
                Open your authenticator app and scan the QR Code. After scanning, you’ll see a six-digit authentication
                code.
            </Typography>
            <Box
                sx={{
                    alignSelf: 'center',
                    padding: 1,
                    border: '1px solid #BFBFBF',
                }}
            >
                <QRCodeSVG fgColor="black" size={160} value={otpUrl} />
            </Box>
            <Typography variant="body1">
                Open your authenticator app and scan the QR Code. After scanning, you’ll see a six-digit authentication
                code.
            </Typography>
            <AuthenticatorCodeContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <AuthenticatorCode>{secret?.match(/.{1,4}/g)?.join(' ')}</AuthenticatorCode>
                </Box>
                <CopyElement onClick={handleCopy}>
                    <Tooltip open={showTooltip} placement={'right'} title={'Copied'}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CopyIcon />
                        </Box>
                    </Tooltip>
                </CopyElement>
            </AuthenticatorCodeContainer>

            <Button
                fullWidth
                sx={{
                    justifySelf: 'flex-end',
                }}
                variant="dark-purple"
                onClick={handleConfirm}
            >
                Next
            </Button>
        </Wrapper>
    );
};
