import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AppConfig } from 'App/AppConfig';
import { BackBtn } from 'Components/BackBtn';
import { PhoneInput } from 'Components/PhoneInput/PhoneInput';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { FORM_ERRORS } from 'frontend-shared-modules/validation/general';
import { isValidEmailOrPhoneNumber } from 'frontend-shared-modules/validation/isValidEmailOrPhoneNumber';
import { Title, Wrapper } from 'Screens/Mfa/style';
import { useLogError } from 'Shared/hooks/useLogError';
import { useMobileMFA } from 'Shared/hooks/useMobileMFA';
import { getErrorMessage } from 'Utils/error';
import * as yup from 'yup';

interface FormData {
    phoneNumber: string;
}

interface PhoneInputScreenProps {
    submitPhone: (phoneNumber: string, onError: (error: any) => void) => void;
    handleBack: () => void;
}

const Form = styled('form')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    flex: 1,
    justifyContent: 'space-between',
}));
const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.darkRed,
}));

export const PhoneInputScreen: React.FC<PhoneInputScreenProps> = ({ submitPhone, handleBack }) => {
    const showDevMode = AppConfig.ENV === 'staging';
    const [isLoading, setIsLoading] = React.useState(false);
    const [devMode, setDevMode] = React.useState(false);
    const [error, setError] = React.useState<any>();
    const { logError } = useLogError();

    const isMobileMfa = useMobileMFA();
    const onPhoneSubmit = React.useCallback(
        ({ phoneNumber }: FormData) => {
            setIsLoading(true);
            submitPhone(phoneNumber, (error) => {
                setIsLoading(false);
                setError(error);
                logError(error);
            });
        },
        [submitPhone],
    );

    const schema = yup.object().shape({
        phoneNumber: yup
            .string()
            .required(FORM_ERRORS.common)
            .test('phone-validation', FORM_ERRORS.phone, (value = '') =>
                isValidEmailOrPhoneNumber({ value, skip: devMode }),
            ),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            phoneNumber: '',
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDevMode(event.target.checked);
    };

    const formData = watch('phoneNumber', '');

    useEffect(() => {
        setError(null);
    }, [formData]);

    return (
        <Wrapper>
            {isMobileMfa && <BackBtn handleBack={handleBack} />}
            {isMobileMfa ? (
                <Title>2 - Step Verification</Title>
            ) : (
                <ScreenHeader withBackButton onBackButtonClick={handleBack}>
                    Set up 2-Step Verification
                </ScreenHeader>
            )}
            <Typography variant="body1">
                Enter your phone number below. An SMS will be sent to that number with a code to enter on the next
                screen.
            </Typography>
            <Form onSubmit={handleSubmit(onPhoneSubmit)}>
                <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field: { onChange, value } }) => (
                        <PhoneInput
                            disableMask={devMode}
                            error={!!errors.phoneNumber}
                            label="Phone Number"
                            value={value}
                            variant="filled"
                            onChange={onChange}
                        />
                    )}
                />
                {error && <ErrorText>{getErrorMessage(error)}</ErrorText>}
                {showDevMode && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={devMode}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={handleChange}
                            />
                        }
                        label="Disable phone validation"
                    />
                )}
                <Box>
                    <Button fullWidth disabled={isLoading} type="submit" variant="dark-purple">
                        Submit
                    </Button>
                </Box>
            </Form>
        </Wrapper>
    );
};
