import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoutesConfig } from './routesConfig';

export const AppRoutes: React.FC = () => {
    return (
        <Routes>
            {RoutesConfig.map((route) => {
                const { path, component: RouteChild } = route;
                return <Route element={<RouteChild />} key={path} path={path} />;
            })}
        </Routes>
    );
};
