import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Styled = {
    Container: styled('div')(() => ({
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })),
    Title: styled(Typography)(() => ({
        fontFamily: 'TWK Everett',
        fontWeight: 500,
        fontSize: 36,
        textAlign: 'center',
        marginBottom: 40,
    })),
};
