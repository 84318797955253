import React from 'react';
import { useDDLogger } from 'Shared/hooks/useDDLogger';

import { AppRoutes } from '../Routes/AppRoutes';

import { AppLayout } from './AppLayout';
import { AppProviders } from './AppProviders';

function App() {
    useDDLogger();

    return (
        <AppProviders>
            <AppLayout>
                <AppRoutes />
            </AppLayout>
        </AppProviders>
    );
}
export default App;
