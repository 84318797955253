import { useMemo } from 'react';
import { useMatch } from 'react-router';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';

import { Routes } from '../../Routes/routesConfig';

export const useMobileMFA = () => {
    const { rentConfig } = useAppInitConfigContext();
    const mfaRouteMatch = useMatch(Routes.MFA);

    return useMemo(() => {
        // unfortunately,  we cant pass additional data to mfa page from mobile app, so we need to check if we are on mfa page and check param from web
        return mfaRouteMatch !== null && !rentConfig?.mfa;
    }, [rentConfig?.mfa, mfaRouteMatch]);
};
