import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArrowBack as ArrowBackIcon } from 'frontend-shared-modules/components/Icon/icons/arrow-back';
import { useBreakpoints } from 'frontend-shared-modules/hooks/useBreakpoints';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';

import { useAppInitConfigContext } from '../../Shared/AppInitConfig/useAppInitConfigContext';

interface ScreenHeaderProps {
    withBackButton?: boolean;
    onBackButtonClick?: () => void;
    titleFontSize?: number;
    marginBottom?: string;
    fontWeight?: number;
}

const BBWrapper = styled(Box)<{ top?: string }>(({ theme, top }) => ({
    position: 'absolute',
    top: top ? top : 0,
    [theme.breakpoints.down('mobileL')]: {
        top: '-42px',
    },
}));

export const ScreenHeader: React.FC<PropsWithChildren<ScreenHeaderProps>> = ({
    children,
    withBackButton,
    onBackButtonClick,
    titleFontSize,
    marginBottom,
    fontWeight,
}) => {
    const { rentConfig } = useAppInitConfigContext();
    const { isMdScreen } = useBreakpoints();
    const { renterScoreActive, renterScoreLandlord } = useRenterScoreTheme();
    return (
        <Box
            mt={!!rentConfig || !isMdScreen ? 3 : 0}
            sx={{
                position: 'relative',
                width: `${renterScoreActive ? '100%' : undefined}`,
            }}
        >
            {withBackButton && onBackButtonClick ? (
                <BBWrapper top={!!rentConfig || !isMdScreen ? '-24px' : undefined}>
                    <IconButton sx={{ padding: !!rentConfig || !isMdScreen ? 0 : 1 }} onClick={onBackButtonClick}>
                        <ArrowBackIcon size={24} />
                    </IconButton>
                </BBWrapper>
            ) : null}
            <Typography
                align="center"
                fontFamily={renterScoreLandlord ? 'GT Planar' : undefined}
                fontSize={titleFontSize || (!!rentConfig || !isMdScreen ? 32 : 36)}
                fontWeight={fontWeight}
                marginBottom={marginBottom}
                textAlign={!isMdScreen && renterScoreActive ? 'left' : 'center'}
            >
                {children}
            </Typography>
        </Box>
    );
};
