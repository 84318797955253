import React from 'react';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import { AppConfig } from 'App/AppConfig';
import { GlobalRoutes } from 'Routes/GlobalRoutes';
import { useLDFlags } from 'Shared/hooks/useLDFlags';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';

const DWOLLA_TERMS_LINK = 'https://www.dwolla.com/legal/tos/';
const DWOLLA_PRIVACY_LINK = 'https://www.dwolla.com/legal/privacy/';

export const Terms = () => {
    const privacyPolicy = AppConfig.APP_URL + GlobalRoutes.PRIVACY_POLICY;
    const termsOfUse = AppConfig.APP_URL + GlobalRoutes.TERMS_OF_USE;
    const { isMTActive, isClientSet } = useLDFlags();
    const { renterScoreActive } = useRenterScoreTheme();
    if (!isClientSet) return null;

    if (AppConfig.APP_ID === 'rent' && isMTActive) {
        return (
            <>
                By selecting a button above and continuing, I consent to {AppConfig.APP_NAME}’s{' '}
                <Link
                    href={termsOfUse}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                    href={privacyPolicy}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    Privacy Policy
                </Link>
                .
            </>
        );
    }
    if (renterScoreActive) {
        return (
            <Box
                sx={{
                    marginTop: '24px',
                    color: '#999',
                }}
            >
                By selecting a button above and creating a Renter Score account, you confirm you’re at least 18 years
                old and agree to our <Link href={privacyPolicy}>E-Sign Consent, User Agreement</Link>
                {' and '}
                <Link href={privacyPolicy}>Privacy Statement</Link>.
            </Box>
        );
    }

    return (
        <>
            By selecting a button above and continuing, I consent to {AppConfig.APP_NAME}’s{' '}
            <Link
                href={termsOfUse}
                sx={{
                    textDecoration: 'none',
                }}
            >
                Terms of Service
            </Link>{' '}
            and{' '}
            <Link
                href={privacyPolicy}
                sx={{
                    textDecoration: 'none',
                }}
            >
                Privacy Policy
            </Link>
            , in addition to our partner{' '}
            <Link
                href={DWOLLA_TERMS_LINK}
                sx={{
                    textDecoration: 'none',
                }}
            >
                Dwolla's Terms of Service
            </Link>{' '}
            and{' '}
            <Link
                href={DWOLLA_PRIVACY_LINK}
                sx={{
                    textDecoration: 'none',
                }}
            >
                Privacy Policy
            </Link>
            .
        </>
    );
};
