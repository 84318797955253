import React, { useCallback, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useBreakpoints } from 'frontend-shared-modules/hooks/useBreakpoints';
import {
    AvailableMethodBtn,
    BtnTextContainer,
    BtnWrapper,
    DescriptionContainer,
    HeaderWrapper,
    Label,
    MethodText,
    MethodTitle,
    Title,
    Wrapper,
} from 'Screens/Mfa/style';
interface AvailableMethodsProps {
    onMethodSelected: (method: string) => void;
    methods: ReadonlyArray<string>;
    enrollments: string[];
    selectedMethod: string;
    alreadyEnrolled?: boolean;
}

const MethodButtonTitle: Record<string, string> = {
    sms: 'Text Message',
    otp: 'Authenticator App',
};
const MethodButtonText: Record<string, string> = {
    sms: 'We’ll text you a verification code',
    otp: 'Use an app (like Google Authenticator) to get a verification code.',
};
export const AvailableMethods: React.FC<AvailableMethodsProps> = ({ onMethodSelected, methods }) => {
    const { isMdScreen } = useBreakpoints();
    const theme = useTheme();
    const [selected, setSelected] = useState('');

    const handleMethodSelected = useCallback(
        (method: string) => {
            if (isMdScreen) {
                setSelected(method);
            } else {
                onMethodSelected(method);
            }
        },
        [isMdScreen, onMethodSelected],
    );

    const handleNext = useCallback(() => {
        if (selected) {
            onMethodSelected(selected);
        }
    }, [selected, onMethodSelected]);

    return (
        <Wrapper>
            <DescriptionContainer>
                <HeaderWrapper>
                    <Title>{isMdScreen ? 'Set up 2-Step Verification' : '2 - Step Verification'}</Title>
                    {isMdScreen ? null : <Label>OFF</Label>}
                </HeaderWrapper>
                <Typography variant="body1">
                    Enable two-step verification for an added level of security when sending payment.
                </Typography>
            </DescriptionContainer>
            <BtnWrapper>
                {methods.map((method) => (
                    <AvailableMethodBtn
                        key={method}
                        methodSelected={selected === method}
                        onClick={() => handleMethodSelected(method)}
                    >
                        <BtnTextContainer>
                            <MethodTitle>{MethodButtonTitle[method]}</MethodTitle>
                            <MethodText>{MethodButtonText[method]}</MethodText>
                        </BtnTextContainer>
                        <ArrowForwardIosIcon
                            style={{
                                fill: theme.palette.common.gray.A450,
                            }}
                        />
                    </AvailableMethodBtn>
                ))}
            </BtnWrapper>
            {isMdScreen && (
                <Button fullWidth color="primary" disabled={!selected} variant="dark-purple" onClick={handleNext}>
                    Next
                </Button>
            )}
        </Wrapper>
    );
};
