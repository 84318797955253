import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
type IProps = {
    handleBack: () => void;
};

const Btn = styled('button')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    alignSelf: 'self-start',
    padding: 0,
});
export const BackBtn: React.FC<IProps> = ({ handleBack }) => {
    const theme = useTheme();
    return (
        <Btn onClick={handleBack}>
            <ArrowBackIosIcon
                style={{
                    fill: theme.palette.common.lightPurple,
                }}
            />
            <Typography color={theme.palette.common.lightPurple} variant={'body1'}>
                Back
            </Typography>
        </Btn>
    );
};
