import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme: { palette } }) => ({
    width: 152,
    display: 'flex',
    alignItems: 'center',
    border: `2px solid ${palette.common.white}`,
    borderRadius: 4,

    '.download-text': {
        textAlign: 'center',
        fontFamily: 'TWK Everett',
        fontSize: 12,
        fontWeight: 500,
    },
}));
