import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { EmailSubmitted as EmailSubmittedIcon } from 'frontend-shared-modules/components/Icon/icons/emailSubmitted';

interface SuccessScreenProps {
    resend: (val: boolean) => void;
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ resend }) => {
    const onResend = useCallback(() => {
        resend(false);
    }, []);

    const { palette } = useTheme();

    return (
        <>
            <Box
                mt={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 180,
                    width: 180,
                    borderRadius: '50%',
                    backgroundColor: palette.common.gray.A50,
                    alignSelf: 'center',
                }}
            >
                <EmailSubmittedIcon size={96} />
            </Box>
            <Typography align="center" mb={12} variant="h4">
                Check your Email
            </Typography>
            <Typography
                sx={{
                    marginBottom: 5,
                }}
                variant="body1"
            >
                If your email address exists in our database, you will receive a password recovery link at your email
                address in a few minutes.
            </Typography>
            <Button variant="outlined" onClick={onResend}>
                Resend Email
            </Button>
        </>
    );
};
