import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BackBtn } from 'Components/BackBtn';
import { OtpCodeInput } from 'Components/OtpCodeInput';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { Title, Wrapper } from 'Screens/Mfa/style';
import { useLogError } from 'Shared/hooks/useLogError';
import { useMobileMFA } from 'Shared/hooks/useMobileMFA';
import { getErrorMessage } from 'Utils/error';
type IProps = {
    error: any;
    handleEnroll: (code: string, onError: (err: any) => void) => void;
    handleBack: () => void;
    setError: (error: any) => void;
};

export const OtpEnrollVerify: React.FC<IProps> = ({ error, handleEnroll, handleBack, setError }) => {
    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const isMobileMfa = useMobileMFA();
    const { logError } = useLogError();
    const enrollWithOtp = useCallback(() => {
        setLoading(true);
        handleEnroll(code, (err) => {
            setLoading(false);
            logError(err);
            setError(err);
        });
    }, [handleEnroll, code]);

    const handleBackBtnClick = useCallback(() => {
        setError(null);
        handleBack();
    }, [handleBack]);

    const handleCodeChange = useCallback((code: string) => {
        if (code.length < 6) {
            setError(null);
        }
        setCode(code);
    }, []);

    return (
        <Wrapper>
            {isMobileMfa && <BackBtn handleBack={handleBackBtnClick} />}
            {isMobileMfa ? (
                <Title>Confirm Your Code</Title>
            ) : (
                <ScreenHeader withBackButton onBackButtonClick={handleBack}>
                    Confirm Your Code
                </ScreenHeader>
            )}
            <Typography variant={'body1'}>Enter the code generated by your app:</Typography>

            <OtpCodeInput error={getErrorMessage(error)} length={6} value={code} onChange={handleCodeChange} />

            <Button fullWidth disabled={loading || code.length !== 6} variant={'dark-purple'} onClick={enrollWithOtp}>
                Confirm
            </Button>
        </Wrapper>
    );
};
