import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled, useTheme } from '@mui/material/styles';
import { AppConfig } from 'App/AppConfig';
import { LogoText as LogoTextIcon } from 'frontend-shared-modules/components/Icon/icons/logoText';
import { useBreakpoints } from 'frontend-shared-modules/hooks/useBreakpoints';
import { AppIdentifiers } from 'types/AppIdentifiers';

interface Props {
    href: string;
}

const StyledLogo = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    display: 'block',
    a: {
        textDecoration: 'none',
        fontFamily: 'GT Planar',
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.05,
        letterSpacing: -0.96,
        color: palette.text.black,
        display: 'block',
    },

    [breakpoints.down('md')]: {
        a: {
            fontSize: 21,
            letterSpacing: -0.63,
        },
    },
}));

export const Logo: React.FC<Props> = ({ href }) => {
    const { palette } = useTheme();
    const { isMdScreen } = useBreakpoints();

    return (
        <StyledLogo>
            <Link aria-label="Homepage" href={href}>
                {AppConfig.APP_ID === AppIdentifiers.RENT ? (
                    <LogoTextIcon color={palette.text.black} size={isMdScreen ? 24 : 22} />
                ) : (
                    AppConfig.APP_NAME
                )}
            </Link>
        </StyledLogo>
    );
};
