import { useEffect, useRef, useState } from 'react';

import type { ReactUtils } from '../../react-app-env';
import { localStorageProvider } from '../../Services/StorageProvider';

type OptionsT<T> = {
    serialize?: (args: T) => string;
    deserialize?: (args: string) => T;
};

export const useLocalStorageState = <StateT>(
    key: string,
    defaultValue: StateT,
    { serialize = JSON.stringify, deserialize = JSON.parse }: OptionsT<StateT> = {},
): [StateT, ReactUtils.SetStateT<StateT>] => {
    const [state, setState] = useState(() => {
        const valueInLocalStorage = localStorageProvider.getItem(key);

        if (valueInLocalStorage) {
            return deserialize(valueInLocalStorage);
        }

        return defaultValue;
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;

        if (prevKey !== key) {
            localStorageProvider.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        localStorageProvider.setItem(key, serialize(state));
    }, [key, state, serialize]);

    return [state, setState];
};
