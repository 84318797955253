import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Wrapper = styled('footer')(({ theme: { palette } }) => ({
    background: palette.primary.main,

    '.MuiContainer-root': {
        maxWidth: '100% !important',
    },
}));
export const ContentWrapper = styled('div')(({ theme: { palette, breakpoints } }) => ({
    padding: '80px 0',
    background: palette.primary.main,
    position: 'relative',
    zIndex: 0,

    [breakpoints.down('md')]: {
        padding: '80px 0 120px',
    },

    '& .social-links': {
        [breakpoints.down('lg')]: {
            display: 'none',
        },
    },
}));

export const LeftWrapper = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const Logo = styled('div')(({ theme: { palette, breakpoints } }) => ({
    padding: '0 0 160px',
    fontFamily: 'GT Planar, sans-serif',
    fontWeight: 500,

    [breakpoints.down('md')]: {
        padding: '0 0 60px',
    },

    '& .app': {
        color: palette.text.white,
        fontSize: 40,
        lineHeight: 1.05,
        letterSpacing: -0.8,
    },

    '& .visible': {
        color: palette.text.gray,
        fontSize: 12,
        marginTop: 4,
    },
}));

export const NavBox = styled(Box)(({ theme: { breakpoints } }) => ({
    display: 'flex',
    justifyContent: 'flex-end',

    [breakpoints.down('md')]: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 40,
        gap: 40,
    },
    [breakpoints.down('mobileS')]: {
        flexDirection: 'column',
    },
}));

export const NavBlock = styled(Box)(({ theme: { breakpoints, palette } }) => ({
    position: 'relative',
    width: 240,
    flexShrink: 0,

    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: -80,
        bottom: -272,
        left: -48,
        width: 1,
        background: palette.text.white,
        opacity: 0.25,
    },

    [breakpoints.down('xl')]: {
        width: 200,
    },

    [breakpoints.down('lg')]: {
        width: 160,
        '&::before': {
            display: 'none',
        },
    },

    [breakpoints.down('md')]: {
        display: 'flex',
        width: 120,
        gap: 24,
    },

    h3: {
        fontSize: 16,
        lineHeight: 1,
        margin: '0 0 64px',
        textTransform: 'uppercase',
        color: '#fff',

        [breakpoints.down('md')]: {
            width: '50%',
            paddingTop: 16,
            borderTop: '1px solid rgba(255, 255, 255, 0.3)',
        },
    },

    ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',

        [breakpoints.down('md')]: {
            paddingTop: 12,
        },

        'li + li': {
            marginTop: 16,
        },

        'li.disabled': {
            a: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        },

        a: {
            color: palette.secondary.main,
            textDecoration: 'none',

            fontSize: 16,
            lineHeight: '120%',

            [breakpoints.down('md')]: {
                fontSize: 14,
            },

            '&:hover, &:focus': {
                textDecoration: 'underline',
            },
        },
    },
}));

export const Copy = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
    color: palette.common.gray.A180,
    display: 'none',
    fontSize: 12,

    '&.lg': {
        [breakpoints.up('lg')]: {
            display: 'block',
        },
    },

    '&.xs': {
        [breakpoints.down('lg')]: {
            display: 'block',
        },
    },
}));

export const MobileCopyright = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    display: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    paddingTop: 20,
    borderTop: `1px solid ${palette.text.darkGray}`,

    '&.xs': {
        [breakpoints.down('lg')]: {
            display: 'flex',
        },
    },
}));
