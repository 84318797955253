import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Wrapper = styled('header')<{ appId: string }>(({ theme: { palette }, appId }) => ({
    width: '100%',
    background: appId === 'rent' ? palette.text.white : 'transparent',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
}));

export const Group = styled('div')(({ theme: { breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    [breakpoints.down('md')]: {
        height: 72,
    },
}));
export const SpaceMaker = styled(Box)(({ theme: { breakpoints } }) => ({
    width: 240,
    display: 'flex',
    alignItems: 'center',
    gap: 16,

    [breakpoints.up('tablet')]: {
        gap: 24,
    },

    '&.right': {
        justifyContent: 'flex-end',
    },
}));

export const UserRole = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
    fontWeight: 500,
    color: palette.text.gray,
    [breakpoints.down('md')]: {
        display: 'none',
    },
}));
