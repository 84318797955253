import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Auth0Error } from 'auth0-js';
import { FORM_ERRORS } from 'frontend-shared-modules/validation/general';
import { Routes } from 'Routes/routesConfig';
import { useAuth0Context } from 'Shared/Auth0Context/useAuth0Context';
import { ErrorMessage } from 'Shared/ErrorMessage/ErrorMessage';
import { useLogError } from 'Shared/hooks/useLogError';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import { PasswordField } from 'Shared/PasswordField/PasswordField';
import { PasswordSuggestions } from 'Shared/PasswordSuggestions/PasswordSuggestions';
import * as yup from 'yup';

interface ISignUpFormValue {
    email: string;
    password: string;
    confirmPassword: string;
}

export const SignUpForm = () => {
    // test
    const { webAuth } = useAuth0Context();
    const { authButtonVariant, renterScoreActive } = useRenterScoreTheme();
    const navigate = useNavigate();
    const [error, setError] = React.useState<Auth0Error | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { logError } = useLogError();
    const onSubmit = useCallback(
        (args: ISignUpFormValue) => {
            setIsLoading(true);
            webAuth?.redirect.signupAndLogin(
                {
                    email: args.email,
                    password: args.password,
                    connection: 'Username-Password-Authentication',
                    responseType: 'code',
                },
                (err) => {
                    setIsLoading(false);

                    if (!err) {
                        return navigate(Routes.SIGNUP_SUCCESS);
                    }
                    logError(err);
                    setError(err);
                },
            );
        },
        [navigate],
    );

    const schema = yup
        .object({
            email: yup.string().trim().email(FORM_ERRORS.email.invalid).required(FORM_ERRORS.email.required),
            password: yup
                .string()
                .required(FORM_ERRORS.password.required)
                //TODO move to shared const
                .matches(/^(?=.*[\d!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'The password is too weak'),
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<ISignUpFormValue>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const formValues = watch();

    useEffect(() => {
        setError(null);
    }, [...Object.values(formValues), setError]);

    const password = watch('password', '');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {error ? <ErrorMessage>{error.description}</ErrorMessage> : null}
            <Box>
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.email?.message}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    borderRadius: '8px 8px 0 0',
                                    marginTop: '-1px',
                                    marginBottom: '-1px',
                                },
                            }}
                            label="Email Address"
                            type="text"
                            variant="filled"
                        />
                    )}
                />
            </Box>
            <Box>
                <Controller
                    control={control}
                    name="password"
                    render={({ field }) => (
                        <PasswordField
                            {...field}
                            fullWidth
                            error={!!errors.password?.message}
                            InputProps={{
                                style: {
                                    borderRadius: '0 0 8px 8px',
                                },
                            }}
                            label="Password"
                            type="password"
                            variant="filled"
                        />
                    )}
                />
            </Box>
            <Box mt={2}>
                <PasswordSuggestions password={password} />
            </Box>
            <Box mt={2} sx={{ marginTop: renterScoreActive ? '56px' : '16px' }}>
                <Button disabled={isLoading} type="submit" variant={authButtonVariant}>
                    {isLoading ? <CircularProgress /> : 'Continue'}
                </Button>
            </Box>
        </form>
    );
};
