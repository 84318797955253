import React, { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LDProvider } from 'launchdarkly-react-client-sdk';

import { AppInitConfigProvider } from '../Shared/AppInitConfig/AppInitConfigProvider';
import { Auth0Provider } from '../Shared/Auth0Context/Auth0Provider';
import { MfaTransactionContextProvider } from '../Shared/MfaTransactionContext/MfaTransactionContextProvider';
import { theme } from '../theme/theme';

import { AppConfig } from './AppConfig';

export const AppProviders: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <AppInitConfigProvider>
            <Auth0Provider>
                <MfaTransactionContextProvider>
                    <LDProvider clientSideID={AppConfig.LD_CLIENT_ID} reactOptions={{ useCamelCaseFlagKeys: false }}>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>{children}</ThemeProvider>
                        </BrowserRouter>
                    </LDProvider>
                </MfaTransactionContextProvider>
            </Auth0Provider>
        </AppInitConfigProvider>
    );
};
