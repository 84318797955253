export interface IResetFormData {
    newPassword: string;
    confirmNewPassword: string;
}

export const confirmResetPassword = async (password: IResetFormData) => {
    const data: Record<string, string> = {
        ...password,
        _csrf,
        email,
        ticket,
    };
    const body = new FormData();

    for (const key in data) {
        body.append(key, data[key]);
    }

    const promise = await fetch('/lo/reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: new URLSearchParams(data).toString(),
    });

    const resp = await promise.json();

    return resp;
};
