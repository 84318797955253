import React from 'react';

import { Title, TitleLabel, TitleRow, TitleWrapper, Wrapper } from './style';

const RenterScoreHeader = () => {
    return (
        <Wrapper>
            <div>
                <TitleRow>
                    <TitleWrapper>
                        <Title>RENTER SCORE</Title>
                    </TitleWrapper>
                    <TitleLabel>AI</TitleLabel>
                </TitleRow>
            </div>
        </Wrapper>
    );
};

export default RenterScoreHeader;
