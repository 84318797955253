import { useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';

export const useLogError = () => {
    const { rentConfig } = useAppInitConfigContext();
    const getMessage = (error: any) => {
        let res = 'auth-error';

        res += ` app_name: "${process.env.REACT_APP_APP_NAME}"`;

        if (error.code) {
            res += ` code: "${error.code}"`;
        }

        return res;
    };

    const logError = useCallback(
        (error: any) => {
            const { code, error: err, description, error_description, statusCode, name } = error;

            const original = err?.original;
            if (err?.original) {
                delete err.original;
            }

            if (error?.name === 'ValidationError') {
                return;
            }

            const message = getMessage(error);

            if (rentConfig?.ddRumSessionId) {
                datadogRum.addAction('auth0-spa-error', {
                    auth0SessionId: rentConfig?.ddRumSessionId,
                    code,
                    error: err,
                    description,
                    error_description,
                    statusCode,
                    name,
                });
            }

            datadogLogs.logger.error(
                message,
                { code, error: err, description, error_description, statusCode, name, original },
                error,
            );
        },
        [rentConfig?.ddRumSessionId],
    );

    return {
        logError,
    };
};
