import { AppIdentifiers } from '../types/AppIdentifiers';

interface IAppConfig {
    ENV: string;
    API_URL: string;
    APP_NAME: string;
    APP_URL: string;
    MAIN_APP_URL: string;
    APP_ID: 'rent' | 'property';
    NFT_BACK_SIDE_MAPBOX_STYLE: string;
    LD_CLIENT_ID: string;
}

export const AppConfig: IAppConfig = {
    ENV: process.env.REACT_APP_ENV || 'local',
    API_URL: process.env.REACT_APP_API_URL as string,
    APP_NAME: (APP_ID === AppIdentifiers.RENT
        ? process.env.REACT_APP_RENT_APP_NAME
        : process.env.REACT_APP_PROPERTY_APP_NAME) as string,
    APP_URL: (APP_ID === AppIdentifiers.RENT
        ? process.env.REACT_APP_RENT_URL
        : process.env.REACT_APP_PROPERTY_URL) as string,
    MAIN_APP_URL: process.env.REACT_APP_MAIN_APP_URL as string,
    APP_ID,
    NFT_BACK_SIDE_MAPBOX_STYLE: 'mapbox://styles/leonidm/cl9e1ot1u000x15nr2umxro7e',
    LD_CLIENT_ID: process.env.REACT_APP_LD_CLIENT_ID as string,
};
