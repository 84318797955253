import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Wrapper = styled('footer')(({ theme: { palette } }) => ({
    background: palette.common.black,
}));

export const ContentWrapper = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    background: palette.common.black,
    color: palette.common.white,

    [breakpoints.down('lg')]: {
        padding: '64px 0',
    },
}));

export const Row = styled(Box)(({ theme: { breakpoints } }) => ({
    display: 'flex',
    [breakpoints.down('lg')]: {
        flexWrap: 'wrap',
    },
}));

export const Col = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    padding: '80px 0 80px 40px',

    [breakpoints.down('lg')]: {
        padding: 0,
    },

    '&.main': {
        width: '100%',
        paddingLeft: 0,
    },

    '&.side': {
        width: 240,
        flexShrink: 0,
        position: 'relative',

        '&::before': {
            content: '""',
            display: 'block',
            width: 1,
            background: palette.common.white,
            opacity: 0.2,
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
        },

        [breakpoints.down('lg')]: {
            width: '33.333%',
            '&::before': {
                display: 'none',
            },
        },

        [breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 48,
        },
    },
}));

export const ColSideGroup = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
}));

export const ColContent = styled(Box)(({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    [breakpoints.down('md')]: {
        gap: 0,
        flexDirection: 'row',
    },

    a: {
        color: 'inherit',
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const ColTitle = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
    fontSize: 12,
    fontWeight: 500,
    color: palette.text.gray,

    '&.social-helper': {
        [breakpoints.up('md')]: {
            display: 'none',
        },
    },

    [breakpoints.down('md')]: {
        width: '50%',
        flexShrink: 0,
    },
}));

export const Logo = styled('div')(({ theme: { palette, breakpoints } }) => ({
    padding: '0 0 120px',
    fontFamily: 'GT Planar, sans-serif',
    fontWeight: 500,

    [breakpoints.down('lg')]: {
        padding: '0 0 48px',
    },

    '& .app': {
        color: palette.text.white,
        fontSize: 40,
        lineHeight: 1.05,
        letterSpacing: -0.8,
    },

    '& .visible': {
        color: palette.text.gray,
        fontSize: 12,
        marginTop: 4,
    },
}));

export const CopyrightDesktop = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    fontFamily: 'GT America, sans-serif',
    color: palette.common.gray.A180,
    fontSize: 12,
    display: 'flex',
    gap: 16,

    [breakpoints.down('lg')]: {
        display: 'none',
    },

    '@media (max-width: 1200px)': {
        flexDirection: 'column',
    },

    a: {
        color: 'inherit',
        textDecorationColor: 'inherit',
        '&:hover': {
            color: palette.common.white,
        },
    },
}));

export const CopyrightMobile = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    fontFamily: 'GT America, sans-serif',
    color: palette.common.gray.A180,
    fontSize: 12,
    display: 'flex',
    gap: 16,

    [breakpoints.up('lg')]: {
        display: 'none',
    },

    '@media (max-width: 389px)': {
        flexDirection: 'column',
        gap: 8,
    },

    a: {
        color: 'inherit',
        textDecorationColor: 'inherit',
        '&:hover': {
            color: palette.common.white,
        },
    },
}));

export const FeaturesDesktop = styled(Box)(({ theme: { breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    marginBottom: 24,

    [breakpoints.down('lg')]: {
        display: 'none',
    },

    '& .soc': {
        width: 68,
    },
}));

export const FeaturesMobile = styled(Box)(({ theme: { breakpoints } }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,

    '& .links': {
        width: '50%',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },

    [breakpoints.up('lg')]: {
        display: 'none',
    },

    '& .soc': {
        width: 68,
    },
}));

export const DownloadAppButton = styled(Button)(({ theme: { palette, breakpoints } }) => ({
    height: 40,
    borderRadius: 20,
    background: palette.common.white,
    color: palette.text.black,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    padding: '0 20px',

    [breakpoints.up('md')]: {
        display: 'none',
    },

    '&:hover': {
        background: palette.common.white,
        color: palette.text.black,
    },
}));
