import { useMemo } from 'react';
import { RentRoles } from 'frontend-shared-modules/types';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';

export const useRenterScoreTheme = () => {
    const { rentConfig } = useAppInitConfigContext();

    const isLandlord = useMemo(() => rentConfig?.renterScoreRole === RentRoles.LANDLORD, [rentConfig?.renterScoreRole]);

    const isRenter = useMemo(() => rentConfig?.renterScoreRole === RentRoles.RENTER, [rentConfig?.renterScoreRole]);

    const renterScoreFlow = useMemo(() => !!rentConfig?.renterScoreRole, [rentConfig]);

    const authBtnVariant = useMemo(() => {
        if (isLandlord) {
            return 'blue';
        }
        return isRenter ? 'black' : 'contained';
    }, [isLandlord, isRenter]);

    return {
        renterScoreActive: renterScoreFlow,
        authButtonVariant: authBtnVariant,
        renterScoreLandlord: isLandlord,
        renterScoreRenter: isRenter,
    };
};
