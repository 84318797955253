import React, { useState } from 'react';
import { OTP } from 'Screens/Mfa/Enrolment/OTP';
import { useMfaTransactionContext } from 'Shared/MfaTransactionContext/useMfaTransactionContext';

import { AvailableMethods } from './AvailableMethods';
import { SMS } from './SMS';

export const Enroll: React.FC = () => {
    const { transaction } = useMfaTransactionContext();
    const [selectedMethod, setSelectedMethod] = useState('');
    const handleBack = () => {
        setSelectedMethod('');
    };
    if (selectedMethod) {
        return selectedMethod === 'otp' ? <OTP handleBack={handleBack} /> : <SMS handleBack={handleBack} />;
    }
    const enrollments = (transaction.enrollments?.[0]?.data?.methods as string[]) || [];

    return (
        <>
            <AvailableMethods
                enrollments={enrollments}
                methods={transaction.getAvailableEnrollmentMethods()}
                selectedMethod={selectedMethod}
                onMethodSelected={setSelectedMethod}
            />
        </>
    );
};
