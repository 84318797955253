import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { AppConfig } from 'App/AppConfig';
import { User as UserIcon } from 'frontend-shared-modules/components/Icon/icons/user';
import { GlobalRoutes } from 'Routes/GlobalRoutes';
import { Routes } from 'Routes/routesConfig';

import { Styled } from './style';

export const UserMenu: React.FC = () => {
    const { palette } = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openNav = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getAvatar = () => {
        return <UserIcon color={palette.text.black} size={32} />;
    };

    return (
        <Box>
            <Styled.Button aria-label="user menu" onClick={openNav}>
                {getAvatar()}
            </Styled.Button>
            <Styled.Menu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                classes={{ paper: 'menu-paper' }}
                id="user-menu"
                open={open}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={handleClose}
            >
                <div>
                    <Styled.Item disableRipple className="welcome">
                        <div>
                            <div className="text-main">Welcome</div>
                        </div>
                        <div className="avatar">{getAvatar()}</div>
                    </Styled.Item>
                    <Styled.Item disableRipple className="link-group">
                        <Link
                            {...(LOGIN_PAGE_CONFIG
                                ? {
                                      component: RouterLink,
                                      to: Routes.LOGIN,
                                  }
                                : {
                                      href: `${AppConfig.APP_URL}/login`,
                                  })}
                        >
                            Sign In / Create Account
                        </Link>
                        <Link href={AppConfig.APP_URL + GlobalRoutes.FAQ}>Help & FAQs</Link>
                    </Styled.Item>
                </div>
            </Styled.Menu>
        </Box>
    );
};
