import React, { useCallback } from 'react';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';
import { useMfaTransactionContext } from 'Shared/MfaTransactionContext/useMfaTransactionContext';

import { CodeInputScreen } from './SMS/CodeInputScreen';
import { PhoneInputScreen } from './SMS/PhoneInputScreen';

type IProps = {
    handleBack: () => void;
};
export const SMS: React.FC<IProps> = ({ handleBack }) => {
    const { transaction } = useMfaTransactionContext();
    const { rentConfig } = useAppInitConfigContext();
    const [enroll, setEnrollData] = React.useState<any>();

    const [phoneNumberState, setPhoneNumberState] = React.useState<string>('');

    const [screen, setScreen] = React.useState<'phone' | 'code'>(phoneNumberState ? 'code' : 'phone');

    const onPhoneSubmit = useCallback(
        (phoneNumber: string, onError: (err: any) => void) => {
            if (!transaction) return;

            setPhoneNumberState(phoneNumber);

            transaction.enroll('sms', { phoneNumber }, (err: any, transactionEnroll: any) => {
                if (err) {
                    onError(err);
                    return;
                }
                setScreen('code');
                setEnrollData(transactionEnroll);
            });
        },
        [setPhoneNumberState, setScreen, transaction],
    );

    const onEnrollClick = useCallback(
        (otpCode: string, onError: (error: any) => void) => {
            if (!enroll) return;
            enroll.confirm({ otpCode }, (err: any) => {
                if (err) {
                    onError(err);
                    return;
                }
            });
        },
        [enroll],
    );

    const handleConfirmBack = useCallback(() => {
        if (rentConfig?.phone) {
            handleBack();
        } else {
            setScreen('phone');
        }
    }, [handleBack, rentConfig?.phone, setScreen]);

    return screen === 'phone' ? (
        <PhoneInputScreen handleBack={handleBack} submitPhone={onPhoneSubmit} />
    ) : (
        <CodeInputScreen
            handleBack={handleConfirmBack}
            initialSend={!!rentConfig?.phone}
            phone={phoneNumberState}
            onResendPhone={onPhoneSubmit}
            onSubmit={onEnrollClick}
        />
    );
};
