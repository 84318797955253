import { styled } from '@mui/material/styles';

export const Social = {
    Wrapper: styled('div')(() => ({
        gap: 24,
        display: 'flex',
    })),
    Link: styled('a')(() => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        textDecoration: 'none',
    })),
};
