import { styled } from '@mui/material/styles';

export const Styled = {
    AppWrapper: styled('div')({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        alignItems: 'center',

        '&.property': {
            background: 'linear-gradient(162deg, #EED8FF 2.8%, #FDFCFE 39.34%, #ADC5D7 99.26%), #EFEFEF',
        },
        '&.dark': {
            background: '#000',
        },
    }),
    ContentWrapper: styled('div')<{ isMfaPage?: boolean }>(({ theme: { breakpoints }, isMfaPage }) => ({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: isMfaPage ? '100%' : 592,
        width: '100%',
        padding: '40px 0',
        gap: 16,
        [breakpoints.up('md')]: {
            padding: isMfaPage ? 0 : '80px 0 48px',
        },
    })),
};
