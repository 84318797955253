import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PaymentSuccess as EmailSubmittedIcon } from 'frontend-shared-modules/components/Icon/icons/payment-success';

import { AppConfig } from '../../App/AppConfig';

interface SuccessScreenProps {
    redirectUrl: string;
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ redirectUrl }) => {
    const onRedirect = useCallback(() => {
        window.location.replace(redirectUrl);
    }, [redirectUrl]);

    const { palette } = useTheme();

    return (
        <>
            <Box
                mt={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 180,
                    width: 180,
                    borderRadius: '50%',
                    alignSelf: 'center',
                }}
            >
                <EmailSubmittedIcon color={palette.success.main} size={96} />
            </Box>
            <Typography align="center" mb={12} variant="h4">
                Password updated successfully
            </Typography>
            <Typography
                sx={{
                    marginBottom: 5,
                }}
                variant="body1"
            >
                You will be redirected to the {AppConfig.APP_NAME} in 5 seconds. If not, click the button below.
            </Typography>
            <Button variant="outlined" onClick={onRedirect}>
                Go back to {AppConfig.APP_NAME}
            </Button>
        </>
    );
};
