import React from 'react';

// @ts-ignore
import qr from './assets/qr.svg';
import { Wrapper } from './style';

export const Download = () => {
    return (
        <Wrapper>
            <img alt="download app qr" src={qr} />
            <div className="download-text">Download Rent App</div>
        </Wrapper>
    );
};
