export const GlobalRoutes = {
    FAQ: '/help',
    CONTACT_US_RENT_US: '/help?a=How-do-I-contact-Customer-Support---id--nJE7a1Y6SSqq_gdWWySGug',
    CONTACT_US_PROPERTY: '/help?a=How-can-I-contact-Customer-Support---id--08hOaIsCQP-Eu1ru2XQ9dQ',
    PRIVACY_POLICY: '/privacy',
    TERMS_OF_USE: '/terms',
    LANDLORD_HOMEPAGE: '/landlord',
    DOWNLOAD_RENT_APP: '/download',
};

export const ExternalRoutes = {
    VISIBLE_HOMEPAGE: 'https://visible.xyz/',
};
