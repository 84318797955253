import { LDFeatureFlags } from 'frontend-shared-modules/types';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

export const useLDFlags = () => {
    const ldClient = useLDClient();
    const flags = useFlags();

    return {
        isClientSet: !!ldClient,
        isMTActive: !!flags[LDFeatureFlags.MODERN_TRESUARY_ACTIVE],
    };
};
