import React from 'react';
import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const PhoneInput: React.FC<TextFieldProps & { disableMask?: boolean }> = ({
    value,
    onChange,
    disabled,
    disableMask,
    ...inputProps
}) => {
    if (disableMask) {
        return <TextField {...inputProps} fullWidth disabled={disabled} value={value} onChange={onChange} />;
    }

    return (
        // @ts-ignore
        <InputMask disabled={disabled} mask="+1 (999) 999-9999" value={value} onChange={onChange}>
            <TextField {...inputProps} fullWidth />
        </InputMask>
    );
};
