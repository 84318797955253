import { Breakpoints, breakpoints as sharedBreakpoints } from 'frontend-shared-modules/theme/breakpoints';

declare module '@mui/material/styles' {
    interface BreakpointOverrides extends Breakpoints {
        mobileS: true;
        mobileM: true;
        mobileL: true;
        desktop: true;
        desktopHD: true;
        desktop2K: true;
    }
}

export const breakpoints = {
    values: {
        ...sharedBreakpoints.values,
        xs: 0,
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        sm: 500,
        md: 768,
        lg: 1024,
        xl: 1440,
        desktop: 1600,
        desktopHD: 1920,
        desktop2K: 2560,
    },
};
