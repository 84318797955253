import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth0EmailLoginHint } from 'frontend-shared-modules/types/user';

import { SignIn } from './SignIn/SignIn';
import { SignUp } from './SignUp/SignUp';

export const Login = () => {
    const [searchParams] = useSearchParams();
    const isSignUp = searchParams.get('login_hint') === Auth0EmailLoginHint.Signup;

    return isSignUp ? <SignUp /> : <SignIn />;
};
