import React from 'react';

import { Social } from './style';

export const SocialLinks: React.FC = () => {
    return (
        <Social.Wrapper>
            <Social.Link aria-label="instagram" href="https://www.instagram.com/rentapp/" target="_blank">
                <svg fill="none" height="24" width="25" xmlns="http://www.w3.org/2000/svg">
                    <path
                        clipRule="evenodd"
                        d="M8 .75A6.75 6.75 0 0 0 1.25 7.5v9A6.75 6.75 0 0 0 8 23.25h9a6.75 6.75 0 0 0 6.75-6.75v-9A6.75 6.75 0 0 0 17 .75H8ZM2.75 7.5C2.75 4.6 5.1 2.25 8 2.25h9c2.9 0 5.25 2.35 5.25 5.25v9c0 2.9-2.35 5.25-5.25 5.25H8a5.25 5.25 0 0 1-5.25-5.25v-9ZM20 6.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm-7.5 1.5a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5ZM6.75 12a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1-11.5 0Z"
                        fill="#fff"
                        fillRule="evenodd"
                    />
                </svg>
            </Social.Link>
            <Social.Link aria-label="twitter" href="https://twitter.com/RentApp" target="_blank">
                <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M.057 1.034 9.162 13.13 0 22.966h2.062l8.022-8.61 6.48 8.61h7.018L13.965 10.19l8.528-9.155h-2.062l-7.387 7.93-5.97-7.93H.058ZM3.09 2.543h3.224l14.235 18.914h-3.224L3.09 2.543Z"
                        fill="#fff"
                    />
                </svg>
            </Social.Link>
            <Social.Link aria-label="tiktok" href="https://www.tiktok.com/@rentapp" target="_blank">
                <svg fill="none" height="24" width="25" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.5 1H13v15.5c0 1.5-1.5 3-3 3s-3-.5-3-3c0-2 1.899-3.339 3.5-3V10c-6.12 0-7 5-7 6.5S4.477 23 10 23c4.522 0 6.5-3.5 6.5-6V8c1.146 1.018 2.922 1.357 5 1.5V6c-3.017 0-5-2.654-5-5Z"
                        fill="#fff"
                    />
                </svg>
            </Social.Link>
        </Social.Wrapper>
    );
};
