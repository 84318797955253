import { GenericErrorScreen } from '../Screens/GenericErrorScreen/GenericErrorScreen';
import { Login } from '../Screens/Login';
import { Mfa } from '../Screens/Mfa/Mfa';
import { RequestPasswordReset } from '../Screens/RequestPasswordReset/RequestPasswordReset';
import { ResetPassword } from '../Screens/ResetPassword/ResetPassword';
import { SignUpSuccess } from '../Screens/SignUpSuccess/SignUpSuccess';

export const Routes = {
    LOGIN: '/login',
    SIGNUP_SUCCESS: '/success',
    REQUEST_RESET_PASSWORD: '/request-reset-password',
    RESET_PASSWORD: '/lo/reset',
    MFA: '/mf',
    ERROR: '/error',
};

export const RoutesConfig = [
    {
        path: Routes.LOGIN,
        component: Login,
    },
    {
        path: Routes.SIGNUP_SUCCESS,
        component: SignUpSuccess,
    },
    {
        path: Routes.REQUEST_RESET_PASSWORD,
        component: RequestPasswordReset,
    },
    {
        path: Routes.RESET_PASSWORD,
        component: ResetPassword,
    },
    {
        path: Routes.MFA,
        component: Mfa,
    },
    {
        path: Routes.ERROR,
        component: GenericErrorScreen,
    },
];
