import React, { useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { AppConfig } from 'App/AppConfig';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { EmailAuth as EmailAuthIcon } from 'frontend-shared-modules/components/Icon/icons/email-auth';
import { AppTypes } from 'frontend-shared-modules/types/auth0';
import { AuthMethod } from 'frontend-shared-modules/types/user';
import { Routes } from 'Routes/routesConfig';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import { SocialButtons } from 'Shared/SocialButtons/SocialButtons';

import { SignUpForm } from './SgnUpForm';
import { Terms } from './Terms';

export const SignUp = () => {
    const { state, search, hash } = useLocation();
    const { renterScoreActive, renterScoreLandlord } = useRenterScoreTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const withDirectEmailForm = LOGIN_PAGE_CONFIG?.connection === AuthMethod.Auth0_Email;
    const [showForm, setShowForm] = React.useState(withDirectEmailForm || state?.showForm || false);
    const isMobileApp = AppTypes.MOBILE === LOGIN_PAGE_CONFIG.extraParams.appType;

    const onShowForm = useCallback(() => {
        setShowForm(true);
    }, []);

    const onBack = useCallback(() => {
        setShowForm(false);
    }, []);

    const switchToSignIn = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.delete('login_hint');
        setSearchParams(searchParams);
    }, []);

    return (
        <>
            {!renterScoreActive && (
                <ScreenHeader
                    titleFontSize={isMobileApp ? 24 : 0}
                    withBackButton={!withDirectEmailForm && showForm}
                    onBackButtonClick={onBack}
                >
                    {isMobileApp ? 'Create Your Login' : `Welcome to ${AppConfig.APP_NAME}`}
                </ScreenHeader>
            )}
            {renterScoreActive && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '24px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '24px',
                        width: '100%',
                    }}
                >
                    <ScreenHeader
                        fontWeight={renterScoreActive ? 500 : undefined}
                        titleFontSize={isMobileApp ? 24 : 0}
                        withBackButton={!withDirectEmailForm && showForm}
                        onBackButtonClick={onBack}
                    >
                        {showForm ? 'Create Your Account' : 'Welcome to Renter Score'}
                    </ScreenHeader>
                    {showForm && (
                        <Typography>
                            {renterScoreLandlord
                                ? 'Create an account and get your unique tenant screening link in seconds.'
                                : 'Set up  account to continue your screening.'}
                        </Typography>
                    )}
                </Box>
            )}
            {showForm ? (
                <SignUpForm />
            ) : (
                <>
                    <SocialButtons />
                    <Button startIcon={<EmailAuthIcon />} onClick={onShowForm}>
                        Continue with Email
                    </Button>
                    <Typography variant="body2">
                        <Terms />
                    </Typography>
                </>
            )}
            <Typography
                align={'center'}
                sx={{
                    marginTop: 'auto',
                }}
                variant="body2"
            >
                Already have an account?{' '}
                <Link
                    component={RouterLink}
                    state={{ showForm }}
                    to={{ pathname: Routes.LOGIN, search, hash }}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => switchToSignIn(e)}
                >
                    Sign in.
                </Link>
            </Typography>
        </>
    );
};
