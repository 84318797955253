import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

function sanitizeUrl(rawUrl?: string | undefined): string {
    if (!rawUrl) return '';

    try {
        const url = new URL(rawUrl);
        return `${url.origin}${url.pathname}`;
    } catch {
        return rawUrl;
    }
}

export const useDDLogger = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENV !== 'development' || process.env.REACT_APP_DD_DEV_MODE === 'true') {
            const ddContext = datadogRum.getInternalContext();

            // already initialized
            if (ddContext) return;

            datadogRum.init({
                applicationId: String(process.env.REACT_APP_DD_APPLICATION_ID),
                clientToken: String(process.env.REACT_APP_DD_RUM_CLIENT_TOKEN),
                site: 'us5.datadoghq.com',
                env: process.env.REACT_APP_ENV,
                service: String(process.env.REACT_APP_APP_NAME),
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                allowedTracingUrls: [String(process.env.REACT_APP_API_URL)],
                defaultPrivacyLevel: 'mask-user-input',

                // remove hash/query params from URL before send
                beforeSend: (event) => {
                    if (event.view?.url) {
                        event.view.url = sanitizeUrl(event.view.url);
                    }

                    if (event.type === 'resource' && event.resource?.url) {
                        event.resource.url = sanitizeUrl(event.resource.url);
                    }

                    if (event.type === 'error' && event.error?.resource?.url) {
                        event.error.resource.url = sanitizeUrl(event.error.resource.url);
                    }

                    return true;
                },
            });

            datadogLogs.init({
                clientToken: String(process.env.REACT_APP_DD_RUM_CLIENT_TOKEN),
                site: 'us5.datadoghq.com',
                env: process.env.REACT_APP_ENV,
                service: String(process.env.REACT_APP_APP_NAME),
                forwardErrorsToLogs: false,
                sessionSampleRate: 100,

                // remove hash/query params from URL before send
                beforeSend: (log) => {
                    if (log.view?.url) {
                        log.view.url = sanitizeUrl(log.view.url);
                    }

                    if (log.http?.url) {
                        log.http.url = sanitizeUrl(log.http.url);
                    }

                    return true;
                },
            });
        }
    }, []);
};
