import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // @ts-ignore
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
