import React, { useCallback, useMemo } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ScreenHeader } from 'Components/ScreenHeader/ScreenHeader';
import { EmailAuth as EmailAuthIcon } from 'frontend-shared-modules/components/Icon/icons/email-auth';
import { Auth0EmailLoginHint, AuthMethod } from 'frontend-shared-modules/types/user';
import { Routes } from 'Routes/routesConfig';
import { useAppInitConfigContext } from 'Shared/AppInitConfig/useAppInitConfigContext';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';
import { SocialButtons } from 'Shared/SocialButtons/SocialButtons';

import { SignInForm } from './SignInForm';

export const SignIn = () => {
    const { state, search, hash } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const withDirectEmailForm = LOGIN_PAGE_CONFIG?.connection === AuthMethod.Auth0_Email;
    const { rentConfig } = useAppInitConfigContext();
    const { renterScoreActive } = useRenterScoreTheme();
    const mfaConfirmation =
        !!(rentConfig?.confirmCredentials && rentConfig?.pwd) ||
        !!(LOGIN_PAGE_CONFIG?.extraParams?.confirmCredentials && LOGIN_PAGE_CONFIG?.extraParams?.pwd);

    const [showForm, setShowForm] = React.useState(withDirectEmailForm || state?.showForm || mfaConfirmation || false);

    const withBackButton = useMemo(() => {
        return !!(rentConfig?.mfa || LOGIN_PAGE_CONFIG?.extraParams?.mfa) ? false : !withDirectEmailForm && showForm;
    }, [rentConfig?.mfa, showForm, withDirectEmailForm, LOGIN_PAGE_CONFIG?.extraParams?.mfa]);

    const onShowForm = useCallback(() => {
        setShowForm(true);
    }, []);

    const onBack = useCallback(() => {
        setShowForm(false);
    }, []);

    const switchToSignUp = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('login_hint', Auth0EmailLoginHint.Signup);
        setSearchParams(searchParams);
    }, []);

    const title = useMemo(() => {
        if (mfaConfirmation) {
            return 'Confirm Your Password';
        }

        if (rentConfig?.loginTitle) {
            return decodeURIComponent(rentConfig.loginTitle);
        }

        return 'Sign in';
    }, [mfaConfirmation, rentConfig]);

    return (
        <>
            <ScreenHeader
                fontWeight={renterScoreActive ? 500 : undefined}
                marginBottom={renterScoreActive ? '24px' : undefined}
                withBackButton={withBackButton}
                onBackButtonClick={onBack}
            >
                {title}
            </ScreenHeader>

            {showForm ? (
                <SignInForm
                    email={rentConfig?.email || LOGIN_PAGE_CONFIG?.extraParams?.email}
                    mfaConfirmation={mfaConfirmation}
                />
            ) : (
                <>
                    <SocialButtons />
                    <Button startIcon={<EmailAuthIcon />} onClick={onShowForm}>
                        Continue with Email
                    </Button>
                </>
            )}
            {!mfaConfirmation && (
                <Typography
                    align="center"
                    sx={{
                        marginTop: 'auto',
                        color: renterScoreActive ? '#BFBFBF' : undefined,
                    }}
                    variant="body2"
                >
                    Don't have an account?{' '}
                    <Link
                        component={RouterLink}
                        state={{ showForm }}
                        to={{ pathname: Routes.LOGIN, search, hash }}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => switchToSignUp(e)}
                    >
                        Create account.
                    </Link>
                </Typography>
            )}
        </>
    );
};
