import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface RecoveryCodeProps {
    onContinue: (authPayload: any) => void;
    authPayload: any;
    recoveryCode: string;
}
export const RecoveryCode: React.FC<RecoveryCodeProps> = ({ authPayload, onContinue, recoveryCode }) => {
    const onContinueClick = useCallback(() => {
        if (!authPayload) return;
        onContinue(authPayload);
    }, [authPayload, onContinue]);

    return (
        <>
            <Typography align="center" variant="h3">
                Almost There!
            </Typography>
            <Typography align="center" variant="body1">
                Copy this recovery code and keep it somewhere safe. You’ll need it if you ever need to log in without
                your device.
            </Typography>
            <p>{recoveryCode}</p>
            <Button fullWidth variant={'contained'} onClick={onContinueClick}>
                Continue
            </Button>
        </>
    );
};
