import { breakpoints } from './breakpoints';

const { values } = breakpoints;

const mobileFirst = {
    xs: `@media (min-width: ${values.xs}px)`,
    mobileS: `@media (min-width: ${values.mobileS}px)`,
    mobileM: `@media (min-width: ${values.mobileM}px)`,
    mobileL: `@media (min-width: ${values.mobileL}px)`,
    sm: `@media (min-width: ${values.sm}px)`,
    md: `@media (min-width: ${values.md}px)`,
    tablet: `@media (min-width: ${values.tablet}px)`,
    lg: `@media (min-width: ${values.lg}px)`,
    xl: `@media (min-width: ${values.xl}px)`,
    desktop: `@media (min-width: ${values.desktop}px)`,
    desktopHD: `@media (min-width: ${values.desktopHD}px)`,
    desktop2K: `@media (min-width: ${values.desktop2K}px)`,
};

const desktopFirst = {
    desktop2K: `@media (min-width: ${values.desktop2K - 1}px)`,
    desktopHD: `@media (min-width: ${values.desktopHD - 1}px)`,
    desktop: `@media (max-width: ${values.desktop - 1}px)`,
    xl: `@media (max-width: ${values.xl - 1}px)`,
    lg: `@media (max-width: ${values.lg - 1}px)`,
    tablet: `@media (min-width: ${values.tablet - 1}px)`,
    md: `@media (max-width: ${values.md - 1}px)`,
    sm: `@media (max-width: ${values.sm - 1}px)`,
    mobileL: `@media (max-width: ${values.mobileL - 1}px)`,
    mobileM: `@media (max-width: ${values.mobileM - 1}px)`,
    mobileS: `@media (max-width: ${values.mobileS - 1}px)`,
};

export const mediaQueries = {
    mobileFirst,
    desktopFirst,
};
