import React from 'react';
import Link from '@mui/material/Link';

import { useAppInitConfigContext } from '../../AppInitConfig/useAppInitConfigContext';

import { Nav } from './style';

export const NavMenu: React.FC = () => {
    const { rentConfig } = useAppInitConfigContext();
    return (
        <Nav>
            <ul>
                <li className="active">
                    <Link href="#">{rentConfig?.role === 'RENTER' ? 'Pay' : 'Collect'} Rent</Link>
                </li>
                <li>
                    <Link href="#">View Transactions</Link>
                </li>
            </ul>
        </Nav>
    );
};
