import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { Apple as AppleIcon } from 'frontend-shared-modules/components/Icon/icons/apple';
import { Google as GoogleIcon } from 'frontend-shared-modules/components/Icon/icons/google';
import { isEmbeddedBrowser } from 'frontend-shared-modules/helpers';

import { useAuth0Context } from '../Auth0Context/useAuth0Context';

export const SocialButtons: React.FC = () => {
    const { webAuth } = useAuth0Context();

    const hideGoogleButton = isEmbeddedBrowser();

    const onGoogleClick = useCallback(() => {
        webAuth?.authorize({
            connection: 'google-oauth2',
        });
    }, [webAuth]);

    const onAppleClick = useCallback(() => {
        webAuth?.authorize({
            connection: 'apple',
        });
    }, [webAuth]);

    return (
        <>
            <Button startIcon={<AppleIcon size={32} />} onClick={onAppleClick}>
                Continue with Apple
            </Button>
            {!hideGoogleButton && (
                <Button startIcon={<GoogleIcon />} onClick={onGoogleClick}>
                    Continue with Google
                </Button>
            )}
        </>
    );
};
