import { Colors, palette as sharedPalette, TextColors } from 'frontend-shared-modules/theme/palette';

declare module '@mui/material/styles/createPalette' {
    // @ts-ignore
    type CommonColors = Colors & {
        darkPurple: string;
        darkPurpleHover: string;
        primary: {
            main: string;
            light: string;
        };
        secondary: {
            main: string;
            dark: string;
        };
    };

    interface TypeText extends TextColors {
        uiGreen: string;
    }
}

export const palette = {
    ...sharedPalette,
    darkPurple: '#3F3B76',
    darkPurpleHover: '#5954A8',
    primary: {
        main: '#000',
        light: '#232329',
    },
    secondary: {
        main: '#fff',
        dark: '#9F9F9F',
    },
};
