import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { EnrollOtp } from 'Screens/Mfa/Enrolment/OTP/EnrollOtp';
import { OtpEnrollVerify } from 'Screens/Mfa/Enrolment/OTP/OtpEnrollVerify';
import { useLogError } from 'Shared/hooks/useLogError';
import { useMfaTransactionContext } from 'Shared/MfaTransactionContext/useMfaTransactionContext';

export enum OtpStages {
    ENROLL = 'ENROLL',
    CONFIRM = 'CONFIRM',
}
type IProps = {
    handleBack: () => void;
};
export const OTP = ({ handleBack }: IProps) => {
    const [stage, setStage] = useState(OtpStages.ENROLL);
    const [enroll, setEnrollData] = useState<any>();
    const [error, setError] = useState<any>();
    const { transaction } = useMfaTransactionContext();
    const { logError } = useLogError();

    useEffect(() => {
        if (!transaction) return;
        transaction.enroll('otp', null, (err: string, transactionEnroll: any) => {
            if (err) {
                setError(err);
                logError(err);
                return;
            }
            setEnrollData(transactionEnroll);
        });
    }, []);

    const onEnrollClick = useCallback(
        (otpCode: string, onError: (error: any) => void) => {
            if (!enroll) return;
            enroll.confirm({ otpCode }, (err: any) => {
                if (err) {
                    onError(err);
                    logError(err);
                    return;
                }
            });
        },
        [enroll],
    );

    const handleConfirmBack = useCallback(() => {
        setStage(OtpStages.ENROLL);
    }, []);

    if (stage === OtpStages.CONFIRM) {
        return (
            <OtpEnrollVerify
                error={error}
                handleBack={handleConfirmBack}
                handleEnroll={onEnrollClick}
                setError={setError}
            />
        );
    } else if (stage === OtpStages.ENROLL && enroll) {
        return <EnrollOtp enroll={enroll} error={error} handleBack={handleBack} setStage={setStage} />;
    }
    return null;
};
