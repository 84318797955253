import React, { PropsWithChildren, Suspense, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import Container from '@mui/material/Container';
import classNames from 'classnames';
import Footer from 'frontend-shared-modules/components/Landing/RenterScore/Footer';
import { AppTypes } from 'frontend-shared-modules/types/auth0';
import { Routes } from 'Routes/routesConfig';
import { useMobileMFA } from 'Shared/hooks/useMobileMFA';
import { useRenterScoreTheme } from 'Shared/hooks/useRenterScoreTheme';

import { useAppInitConfigContext } from '../Shared/AppInitConfig/useAppInitConfigContext';
import FooterPropertyApp from '../Shared/FooterPropertyApp';
import FooterRentApp from '../Shared/FooterRentApp';
import Header from '../Shared/Header';
import RenterScoreHeader from '../Shared/RenterScoreHeader';

import { MapLayoutAsync as MapLayout } from './MapWrapper/MapWrapperAsync';
import { NftWrapperAsync as NftWrapper } from './NftWrapper/NftWrapperAsync';
import { Styled } from './styles';
export const AppLayout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { rentConfig, nftConfig } = useAppInitConfigContext();
    const { renterScoreActive } = useRenterScoreTheme();
    const isMobileMfa = useMobileMFA();
    const isMobileApp = AppTypes.MOBILE === LOGIN_PAGE_CONFIG?.extraParams.appType;
    const mfaRouteMatch = useMatch(Routes.MFA);

    const PageFooter = useMemo(() => {
        if (rentConfig) {
            return renterScoreActive ? Footer : FooterRentApp;
        } else {
            return FooterPropertyApp;
        }
    }, []);
    const PageHeader = renterScoreActive ? RenterScoreHeader : Header;

    useEffect(() => {
        if (!rentConfig?.ddRumSessionId) return;
        datadogRum.addAction('auth0-spa-loaded', { auth0SessionId: rentConfig?.ddRumSessionId });
        datadogLogs.logger.setContext({ session_id: rentConfig.ddRumSessionId });
    }, [rentConfig?.ddRumSessionId]);

    return (
        <>
            <Styled.AppWrapper
                className={classNames({
                    property: APP_ID === 'property',
                })}
            >
                {isMobileApp || isMobileMfa ? null : <PageHeader />}
                {nftConfig ? (
                    <Suspense>
                        <NftWrapper>{children}</NftWrapper>
                    </Suspense>
                ) : null}
                {rentConfig?.mapLatitude && rentConfig.mapLongitude ? (
                    <Suspense>
                        <MapLayout>{children}</MapLayout>
                    </Suspense>
                ) : null}

                {!nftConfig && !rentConfig?.mapLatitude && !rentConfig?.mapLongitude ? (
                    <Container
                        maxWidth={'xl'}
                        sx={{
                            flex: 1,
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Styled.ContentWrapper isMfaPage={!!(rentConfig?.mfa && mfaRouteMatch)}>
                            {children}
                        </Styled.ContentWrapper>
                    </Container>
                ) : null}
            </Styled.AppWrapper>
            {isMobileApp || isMobileMfa ? null : <PageFooter />}
        </>
    );
};
