import { Box, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme: { palette, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '32px 40px',
    backgroundColor: palette.common.black,
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${palette.text.darkGray}`,
    [breakpoints.down('md')]: {
        padding: '16px 32px',
    },
}));

export const TitleWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

export const TitleRow = styled(Box)(({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    height: '32px',
    [breakpoints.down('md')]: {
        height: '20px',
    },
}));

export const TitleLabel = styled('div')(({ theme: { palette, breakpoints } }) => ({
    boxSizing: 'border-box',
    padding: '3px 8px',
    borderRadius: 4,
    border: 'none',
    backgroundColor: '#FFD700',
    fontFamily: 'GT Planar',
    fontSize: '8px',
    height: '12px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.text.black,
    [breakpoints.down('md')]: {
        fontSize: '6px',
        height: '8px',
        padding: '2px 4px',
        borderRadius: '2px',
    },
}));

export const Title = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
    color: palette.text.white,
    fontSize: '30px',
    fontFamily: 'GT Planar',
    [breakpoints.down('md')]: {
        fontSize: '18px',
    },
}));

export const Link = styled('a')(({ theme: { palette } }) => ({
    color: palette.text.white,
    fontSize: '14px',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'TWK Everett',
    fontWeight: 500,
    '&:hover': {
        textDecoration: 'underline',
    },
}));
