import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { OtpCodeInput } from 'Components/OtpCodeInput';
import { SubmitContainer, Title, Wrapper } from 'Screens/Mfa/style';
import { useLogError } from 'Shared/hooks/useLogError';
import { useMfaTransactionContext } from 'Shared/MfaTransactionContext/useMfaTransactionContext';
import { getErrorMessage } from 'Utils/error';

export const Auth: React.FC = () => {
    const { palette } = useTheme();
    const { logError } = useLogError();
    const { transaction } = useMfaTransactionContext();
    const [auth, setAuth] = React.useState<any>();
    const [method, setMethod] = React.useState<'otp' | 'sms'>();
    const [enrollment, setEnrollment] = React.useState<any>();
    const [error, setError] = React.useState<any>();
    const [code, setCode] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const formatPhoneNumber = (phone: string) => {
        const last4 = phone.substring(phone.length - 4);
        return `(XXX) XXX-${last4}`;
    };

    const onSubmit = React.useCallback(
        (code: string) => {
            if (!auth) return;
            auth.verify({ otpCode: code }, (err: any) => {
                setLoading(true);
                if (err) {
                    setLoading(false);
                    setError(err);
                    logError(err);
                    return;
                }
            });
        },
        [auth, code],
    );

    const handleCodeChange = useCallback((code: string) => {
        if (code.length < 6) {
            setError(null);
        }
        setCode(code);
    }, []);

    const resendCode = useCallback(() => {
        if (!transaction) return;
        const enrollment = transaction.getEnrollments()[0];
        transaction.requestAuth(enrollment, { method: method }, function (err: any) {
            if (err) {
                setError(err);
                logError(err);
            }
        });
    }, []);

    useEffect(() => {
        if (!transaction) return;

        const enrollment = transaction.getEnrollments()[0];
        const availableMethods = enrollment.getAvailableAuthenticatorTypes();
        const method = availableMethods[0];

        transaction.requestAuth(enrollment, { method: method }, function (err: any, auth: any) {
            if (err) {
                setError(err);
                logError(err);
            }
            setEnrollment(enrollment);
            setMethod(method);
            setAuth(auth);
        });
    }, [transaction]);

    useEffect(() => {
        setLoading(false);
        setError(null);
    }, []);

    const highlightOnError = useMemo(() => {
        return error?.errorCode !== 'too_many_sms';
    }, [error]);

    return (
        <Wrapper>
            <Title>Confirm Your Identity</Title>
            <Typography variant="body1">
                {method === 'otp'
                    ? 'Enter the code generated by your app:'
                    : `Enter the code sent to ${
                          enrollment?.getPhoneNumber() ? formatPhoneNumber(enrollment?.getPhoneNumber()) : 'your phone'
                      }:`}
            </Typography>
            <OtpCodeInput
                error={getErrorMessage(error)}
                highlightOnError={highlightOnError}
                length={6}
                value={code}
                onChange={handleCodeChange}
            />
            <SubmitContainer>
                {method === 'sms' && (
                    <Typography align="center" color={palette.text.gray} variant="body1">
                        Didn't receive a code?{' '}
                        <Link href="#" onClick={resendCode}>
                            Resend
                        </Link>
                    </Typography>
                )}
                <Button
                    fullWidth
                    color="primary"
                    disabled={loading || code.length !== 6}
                    type="submit"
                    variant="contained"
                    onClick={() => onSubmit(code)}
                >
                    Continue
                </Button>
            </SubmitContainer>
        </Wrapper>
    );
};
