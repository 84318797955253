import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ErrorMessage: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { palette } = useTheme();
    return (
        <Box
            sx={{
                padding: '16px 24px',
                backgroundColor: palette.common.darkRed,
                borderRadius: '4px',
            }}
        >
            <Typography color={palette.text.white} fontSize={14} variant="body1">
                {children}
            </Typography>
        </Box>
    );
};
