import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const HeaderWrapper = styled('div')(({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoints.up('md')]: {
        justifyContent: 'center',
    },
}));

export const Title = styled(Typography)(({ theme: { breakpoints } }) => ({
    fontWeight: 500,
    fontFamily: 'GT America',
    fontSize: '36px',
    alignSelf: 'center',

    [breakpoints.between('xs', 'md')]: {
        alignSelf: 'flex-start',
        fontSize: '24px',
        align: 'left',
    },
}));

export const Wrapper = styled('div')<{ padding?: string }>(({ theme: { breakpoints }, padding }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: padding ? padding : '0px',
    alignSelf: 'center',
    marginBottom: '40px',

    [breakpoints.up('md')]: {
        maxWidth: 720,
        width: '100%',
    },
    [breakpoints.between('xs', 'md')]: {
        flex: 1,
        margin: '0 -12px',
    },
    [breakpoints.down('mobileL')]: {
        width: '100%',
    },
}));
export const SubmitContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    gap: '16px',
}));
export const DescriptionContainer = styled('div')(({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '0 24px',
    [breakpoints.between('xs', 'md')]: {
        padding: 0,
    },
}));

export const AvailableMethodBtn = styled('button')<{ methodSelected: boolean }>(
    ({ theme: { palette, breakpoints }, methodSelected }) => ({
        backgroundColor: palette.common.white,
        padding: '24px',
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        cursor: 'pointer',
        alignItems: 'center',
        border: `${methodSelected ? '2px' : '1px'} solid ${
            methodSelected ? palette.common.lightPurple : palette.common.gray.A450
        }`,
        borderRadius: '8px',

        [breakpoints.between('xs', 'md')]: {
            borderRadius: '0',
            '&:last-child': {
                borderBottom: 'none',
                borderRight: 'none',
                borderLeft: 'none',
            },
            '&:first-child': {
                borderRight: 'none',
                borderLeft: 'none',
            },
        },
    }),
);

export const BtnWrapper = styled('div')(({ theme: { breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '0',
    gap: '16px',
    [breakpoints.between('xs', 'md')]: {
        margin: '0 -24px',
        padding: 0,
        gap: 0,
    },

    [breakpoints.down('mobileL')]: {
        margin: '0 -32px',
    },
}));

export const BtnTextContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flex: 1,
}));
export const MethodTitle = styled(Typography)(({ theme: { palette } }) => ({
    textAlign: 'left',
    fontSize: '16px',
    color: palette.common.black,
    fontWeight: 500,
}));
export const MethodText = styled(Typography)(({ theme: { palette } }) => ({
    textAlign: 'left',
    fontSize: '16px',
    color: palette.common.gray.A450,
}));

export const Label = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '5px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    border: `1px solid ${theme.palette.common.gray.A450}`,
    fontSize: '10px',
    textTransform: 'uppercase',
    color: theme.palette.common.gray.A450,
    fontWeight: 700,
    fontFamily: 'TWK Everett',
    width: 'min-content',
    height: 'min-content',
}));

export const AuthenticatorCodeContainer = styled('div')(({ theme: { palette, breakpoints } }) => ({
    display: 'flex',
    padding: '24px',
    borderRadius: '8px',
    flexDirection: 'row',
    gap: '8px',
    backgroundColor: palette.common.gray.A50,
    border: `1px solid ${palette.common.gray.A450}`,
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('mobileL')]: {
        padding: '12px',
        fontSize: '11px',
    },
}));

export const AuthenticatorCode = styled(Typography)(() => ({
    fontFamily: 'GT America Mono',
    fontSize: '12px',
}));
