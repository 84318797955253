import React, { PropsWithChildren } from 'react';

import { IAppConfig } from './useAppInitConfig';
import { useInitAppConfig } from './useAppInitConfig';

export const AppInitConfigContext = React.createContext<IAppConfig>({});

export const AppInitConfigProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const config = useInitAppConfig();
    return <AppInitConfigContext.Provider value={config}>{children}</AppInitConfigContext.Provider>;
};
