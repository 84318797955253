import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { PasswordEye as PasswordEyeIcon } from 'frontend-shared-modules/components/Icon/icons/passwordEye';
import { PasswordEyeOpen as PasswordEyeOpenIcon } from 'frontend-shared-modules/components/Icon/icons/passwordEyeOpen';

// eslint-disable-next-line react/display-name
export const PasswordField = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof TextField>>(
    ({ ...props }, ref) => {
        const [showPassword, setShowPassword] = useState(false);
        const handleClickShowPassword = useCallback(() => {
            setShowPassword(!showPassword);
        }, [showPassword]);

        return (
            <TextField
                {...props}
                InputProps={{
                    // eslint-disable-next-line react/prop-types
                    ...props.InputProps,
                    style: {
                        // eslint-disable-next-line react/prop-types
                        ...props?.InputProps?.style,
                    },
                    autoCorrect: 'off',
                    spellCheck: false,
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle visibility" onClick={handleClickShowPassword}>
                                {showPassword ? <PasswordEyeOpenIcon size={24} /> : <PasswordEyeIcon size={24} />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                ref={ref}
                type={showPassword ? 'text' : 'password'}
            />
        );
    },
);
/*
 text-security:disc;
    -webkit-text-security:disc;
    -mox-text-security:disc;
 */
