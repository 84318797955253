import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Styled = {
    Container: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    })),

    Item: styled(Typography)(({ theme: { palette } }) => ({
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        color: palette.common.gray.A180,
        gap: 8,

        '&.green': {
            color: palette.text.uiGreen,
        },
    })),
};
