import React, { PropsWithChildren, useMemo } from 'react';

import { MfaTransactionContext } from './MfaTransactionContext';

export const MfaTransactionContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [transaction, setTransaction] = React.useState();

    const value = useMemo(() => {
        return {
            transaction,
            setTransaction,
        };
    }, [transaction, setTransaction]);
    return <MfaTransactionContext.Provider value={value}>{children}</MfaTransactionContext.Provider>;
};
